import React from 'react';
import styled from '@emotion/styled';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import shouldForwardProp from '@emotion/is-prop-valid';

export const Paper = styled('div', { shouldForwardProp })<{ gapSize?: number }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(4)}px
    ${({ theme }) => theme.spacing(6)}px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 900px;
  aspect-ratio: 1 / 1.41; /* A4 aspect ratio */
  gap: ${({ gapSize = 0, theme }) => theme.spacing(gapSize)}px;

  @media print {
    box-shadow: none;
    padding: 0 ${({ theme }) => theme.spacing(6)}px;

    table {
      page-break-inside: avoid;
    }
  }
`;

export const TaxTableWrapper = styled.table`
  margin: ${({ theme }) => theme.spacing(0.5)}px 0;
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #000;
    padding: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 18px; // page number margin, to be removed when we have page numbers
`;

export const LogoOrName = ({
  orgName,
  city,
  postalCode,
}: {
  orgName: string | undefined;
  city: string | null | undefined;
  postalCode: string | null | undefined;
}) => (
  <LogoWrapper>
    <Typography variant="h3" margin="none">
      {orgName}
    </Typography>
    <Typography variant="caption" margin="none">
      {city} {postalCode}
    </Typography>
  </LogoWrapper>
);

import { useLayoutEffect, useState } from 'react';

const useDropdownDimensions = (
  anchorRef: React.RefObject<HTMLButtonElement>,
  itemHeight: number,
  isOpen: boolean
) => {
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (!anchorRef.current) return;

    const rect = anchorRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    setParentWidth(anchorRef.current.offsetWidth);

    // Calculate maxHeight: space below input minus 16px for padding
    const availableHeight = viewportHeight - rect.bottom - 16;
    setMaxHeight(Math.max(availableHeight, itemHeight));
  }, [isOpen, anchorRef, itemHeight]);

  return { parentWidth, maxHeight };
};

export default useDropdownDimensions;

import * as t from 'io-ts';
import {
  AgoyDocumentNode,
  AgoyDocumentStructureExternalDocument,
  AgoyDocumentStructureExternalDocuments,
  AgoyDocumentStructurePart,
  AgoyDocumentStructureRelatedClient,
  Field as AgoyField,
  AgoyTable,
  StringCell,
} from '@agoy/document';

import {
  TaxDeclarationHelp,
  TaxDeclarationHelpStructure,
  K10 as helpStructureK10,
  INK2 as helpStructureINK2,
  INK3 as helpStructureINK3,
  INK4 as helpStructureINK4,
} from './help';

export {
  helpStructureK10,
  helpStructureINK2,
  helpStructureINK3,
  helpStructureINK4,
};

/**
 * Document structure
 *
 * @param H The type of structure of the form helper, if one exists
 */
export type TaxDeclarationFormStructure = {
  type: 'document';
  children: Record<
    string,
    | {
        type: 'part';
        partType: 'form' | 'coverLetter' | 'taxSummary';
        children: {
          fields: {
            type: 'part';
            children: Record<
              string,
              | {
                  type: 'field';
                }
              | { type: 'part'; children: Record<string, { type: 'table' }> }
            >;
          };
          externalFields?: {
            type: 'part';
            children: Record<
              string,
              {
                type: 'field';
              }
            >;
          };
          sru: {
            type: 'part';
            children: Record<
              string,
              {
                type: 'field';
              }
            >;
          };
          derivedFields?: {
            type: 'part';
            children: Record<
              string,
              {
                type: 'field';
              }
            >;
          };
          help?: TaxDeclarationHelpStructure;
        };
      }
    | (AgoyDocumentStructurePart & {
        partType: 'additionalPages';
      })
    | AgoyDocumentStructureExternalDocuments
    | AgoyDocumentStructureExternalDocument
    | AgoyDocumentStructureRelatedClient
  >;
};

export type TaxDeclarationFormDataConfig = {
  initial: TaxDeclarationFormData;
  definition: TaxDeclarationFormStructure;
};

// Individual form definition
export type TaxDeclarationFormDefinition = FormDefinition & {
  parts?: FormPartContentDefinition[];
};

export type FormCategory =
  | 'main'
  | 'sub'
  | 'helper'
  | 'other'
  | 'coverLetter'
  | 'taxSummary';

export function isFormCategory(category: string): category is FormCategory {
  return ['main', 'sub', 'helper', 'other'].includes(category);
}

export const RelatedClientCodec = t.record(
  t.string,
  t.strict({
    information: t.array(
      t.union([t.literal('clientInformation'), t.literal('ownership')])
    ),
  })
);

type RelatedClient = t.TypeOf<typeof RelatedClientCodec>;

export type FormDefinition = {
  id: string;
  domain: string; // SKV
  type: string;
  typeId?: string;
  category: FormCategory;
  name: string;
  subFormsIds?: string[];
  thumbnailUrl?: string;
  readOnly: boolean;
  externalDocuments: Record<
    string,
    {
      documentType: string;
      multiple: boolean;
      financialYear?: number;
      relationType?: 'ownership' | 'sibling' | 'ownedByOwner';
    }
  >;
  /**
   * The version number of the forms config that is used in the current environment.
   *
   * Note that only one version of the config of a form can exist in an environment.
   *
   * Once the version number is changed in the table skv_form, all existing document
   * use that new version of the config.
   */
  version: number;

  /**
   * Map of named relationships to other clients as defined in `@agoy/documents`
   * `AgoyDocumentStructureRelatedClient`.
   *
   * The key is the name of the relationship, for example 'company' in K10,
   * and the set of information that is requested for this relationship.
   */
  relatedClient: RelatedClient | null;
};

export type Field = {
  type: 'number' | 'text' | 'checkbox';
  id: string;
  x: number;
  y: number;
  w: number;
  h?: number;
  hideBackground?: boolean;
  readOnly?: boolean;
};
export type FormPageContentDefinition = {
  imageUrl: string;
  fields: Field[];
};

export type ExternalFields = {
  fields: Field[];
};
export type FormPartWithContentDefinition = {
  id: string;
  name: string;
  type: 'form';
  externalFields?: Field[];
  pages: FormPageContentDefinition[];
  derivedFields?: Field[];
};

export type EmptyFormPart = {
  type: 'empty';
  externalFields?: Field[];
  pages: number;
};

export type FormPartContentDefinition<T = FormPartWithContentDefinition> =
  | T
  | EmptyFormPart;

export type FormContentDefinition = {
  id: string;
  parts: FormPartContentDefinition[];
};

export type HelpStructure = AgoyDocumentStructurePart;

export type TaxDeclarationFormData = {
  [id: string]:
    | TaxDeclarationFormPartData
    | (AgoyDocumentNode<AgoyDocumentStructurePart> & {
        partType: 'additionalPages';
      })
    | AgoyDocumentNode<AgoyDocumentStructureExternalDocument>
    | AgoyDocumentNode<AgoyDocumentStructureExternalDocuments>
    | AgoyDocumentNode<AgoyDocumentStructureRelatedClient>;
};

export type CoverLetterFields = {
  name: AgoyField;
  personalNumber: AgoyField;
  taxYear: AgoyField;
  missionTitle: AgoyField;
  missionText: AgoyField;
  responsibilityTitle: AgoyField;
  responsibilityText: AgoyField;

  signatureTitle: AgoyField;
  signatureOfIncomeDeclaration: AgoyField;
  signatureText: AgoyField;

  toSkatteverketTitle: AgoyField;
  toSkatteverketCheckbox1: AgoyField;
  toSkatteverketCheckbox2: AgoyField;
  toSkatteverketCheckbox3: AgoyField;
  toSkatteverketCheckbox4: AgoyField;
  toSkatteverketText: AgoyField;

  taxCalculationTitle: AgoyField;
  taxCalculationText: AgoyField;
  estimatedFinalTax: AgoyField;
  estimatedTaxRefund: AgoyField;
  fTax: AgoyField;

  paymentToSkatteverketTitle: AgoyField;
  paymentToSkatteverketText: AgoyField;
  paymentToSkatteverketAmount: AgoyField;
  paymentToSkatteverketDate: AgoyField;

  commentTitle: AgoyField;
  commentText: AgoyField;
};

export type TaxSummaryFields = {
  name: AgoyField;
  personalNumber: AgoyField;
  date: AgoyField;
  financialYear: AgoyField;
  taxYear: AgoyField;
  municipality: AgoyField;
  burialFeeRate: AgoyField;
  municipalTaxRate: AgoyField;
  religiousCommunitiesFee: AgoyField;
  SammanstallningForvarvsinkomst: { table: AgoyTable };
  SammanstallningKapitalinkomst: { table: AgoyTable };
  SkatterOchAvgifter: { table: AgoyTable };
  EnskildNaringsverksamhet: { table: AgoyTable };
  EgenavgifterOchLoneavgift: { table: AgoyTable };
  Skattereduktioner: { table: AgoyTable };
  SammanstalldSkatt: { table: AgoyTable };
  SammanstallningBerakningar: { table: AgoyTable };
};

type BaseTaxDeclarationFormPart = {
  id: string;
  externalFields?: TaxDeclarationFormPartFields;
  sru: TaxDeclarationFormPartSRUs;
  derivedFields?: TaxDeclarationFormPartFields;
  help?: TaxDeclarationHelp;
  companyType?: StringCell;
  financialYearStart?: StringCell;
  financialYearEnd?: StringCell;
  orgNumber?: StringCell;
};

export type TaxDeclarationFormPart = BaseTaxDeclarationFormPart & {
  partType: 'form';
  fields: TaxDeclarationFormPartFields;
};

export type CoverLetterPart = BaseTaxDeclarationFormPart & {
  partType: 'coverLetter';
  fields: CoverLetterFields;
};

export type TaxSummaryPart = BaseTaxDeclarationFormPart & {
  partType: 'taxSummary';
  fields: TaxSummaryFields;
};

export type TaxDeclarationFormPartData =
  | TaxDeclarationFormPart
  | CoverLetterPart
  | TaxSummaryPart;

export type TaxDeclarationFormPartFields = {
  [field: string]: AgoyField;
};

export type TaxDeclarationFormPartSRUs = {
  [field: string]: AgoyField;
};

// Form configuration
export type CompanyMetaData = {
  orgNumber: string;
  name: string;
  address: string;
  contactPerson: string;
  phoneNumber: string;
  type: string;
};

export type PersonMetaData = {
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
  city: string;
  personNumber: string;
};

export const isCompanyType = (
  customer: CompanyMetaData | PersonMetaData
): customer is CompanyMetaData => {
  return (customer as CompanyMetaData).orgNumber !== undefined;
};

const isTaxDeclarationPart = (
  part: TaxDeclarationFormData[string],
  type: string
): part is TaxDeclarationFormPart => {
  return (
    typeof part === 'object' && 'partType' in part && part.partType === type
  );
};

export const isTaxDeclarationFormPartData = (
  part: TaxDeclarationFormData[string]
): part is TaxDeclarationFormPart => {
  return isTaxDeclarationPart(part, 'form');
};

export const isTaxDeclarationCoverLetter = (
  part: TaxDeclarationFormData[string]
): part is TaxDeclarationFormPart => {
  return isTaxDeclarationPart(part, 'coverLetter');
};

export const isTaxDeclarationTaxSummary = (
  part: TaxDeclarationFormData[string]
): part is TaxDeclarationFormPart => {
  return isTaxDeclarationPart(part, 'taxSummary');
};

import styled from '@emotion/styled';
import React from 'react';
import { useDrag } from 'react-dnd';

const ItemType = 'TABLE_ITEM';

const Th = styled.th`
  white-space: nowrap;
  cursor: grab;
  width: 100px;

  p {
    white-space: nowrap;
  }
`;

type DraggableHeaderProps = {
  children: React.ReactNode;
  columnIndex: number;
  moveColumn: (
    fromRowIndex: number,
    fromColumnIndex: number,
    toRowIndex: number,
    toColumnIndex: number
  ) => void;
};

const DraggableHeader = ({
  children,
  columnIndex,
  moveColumn,
}: DraggableHeaderProps) => {
  const [, drag] = useDrag({
    type: ItemType,
    item: { rowIndex: -1, columnIndex, isRow: true },
  });

  return <Th ref={drag}>{children}</Th>;
};

export default DraggableHeader;

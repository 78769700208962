import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormField } from 'redux/actions';
import styled from '@emotion/styled';

import { Field, stringValue, booleanValue } from '@agoy/document';
import { formatPercentage, getClasses, parsePercentage } from '@agoy/common';

import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import SharedCheckbox from '_shared/components/Controls/Checkbox';
import { SimplifiedDatePicker } from '_shared/components/Inputs';
import Typography from '_shared/components/Typography/Typography';
import When from '_shared/components/When/When';
import TaxInput from '../../Input/Input';
import { InputContainer } from '../../Input/InputContainers';

const COVER_LETTER_KEY = 'CL';
const TAX_SUMMARY_KEY = 'TS';

export const StyledDateField = styled(SimplifiedDatePicker)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  flex: 1;

  .MuiInputBase-input {
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 2px;
  }
  .MuiInputBase-root {
    background: #f2f4ff;
    padding-right: 0;
    border-radius: ${({ theme }) => theme.spacing(0.25)}px;
  }

  .MuiInputBase-root:hover {
    background: #dddfe9;
  }

  .MuiIconButton-root {
    padding: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  fieldset {
    border: none;
  }

  @media print {
    .MuiInputBase-root {
      background: none;
    }

    svg {
      display: none;
    }
  }
`;

export type PartType = 'coverLetter' | 'taxSummary';

type InputProps = {
  field: Field;
  keyValue: string;
  partType: PartType;
  label?: string;
  title?: boolean;
  className?: string;
  multiline?: boolean;
  type?: 'date' | 'percentage';
  displayDecimals?: number;
};

const Input = ({
  field,
  keyValue,
  label,
  partType,
  title = false,
  className,
  multiline = false,
  type,
  displayDecimals = 2,
}: InputProps) => {
  const dispatch = useDispatch();
  const value = stringValue(field);

  const partKey =
    partType === 'coverLetter' ? COVER_LETTER_KEY : TAX_SUMMARY_KEY;

  // We check if we are in print context here
  const { state } = useContext(PrintStateContext);
  const isPrint = state?.additionalPages;

  // In print and if there is no value in field without label return null
  if (isPrint && !value && !label && field.type !== 'number') {
    return <span />;
  }

  const handleChangeDate = (date) => {
    if (!date) {
      return;
    }
    dispatch(updateTaxDeclarationFormField(partKey, keyValue, date));
  };

  if (type === 'date') {
    return (
      <InputContainer>
        <When isTrue={!!label}>
          <Typography variant="body2" as="label" margin="none">
            {label}
          </Typography>
        </When>
        <StyledDateField
          //  add optional label prop here
          editing={!isPrint}
          value={value}
          size="small"
          onChange={handleChangeDate}
        />
      </InputContainer>
    );
  }

  return (
    <TaxInput
      partId={partKey}
      cell={field}
      label={label}
      fieldId={keyValue}
      className={className}
      formatter={(v) =>
        type === 'percentage'
          ? formatPercentage(v, displayDecimals)
          : v?.toString() ?? ''
      }
      parser={(v) =>
        type === 'percentage' ? parsePercentage(v) : parseFloat(v) ?? undefined
      }
      multiline={multiline}
      isTitle={title}
    />
  );
};

type RadioProps = {
  children: React.ReactElement<RadioOptionProps>[];
  defaultValue?: string;
  onChange: (value: string) => void;
};

const Radio = ({ children, onChange, defaultValue }: RadioProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue
  );

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          checked: child.props.value === selectedValue,
          onChange: () => handleChange(child.props.value),
        });
      })}
    </>
  );
};

const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;

  input[type='radio'] {
    appearance: none;
    background: #f2f4ff;
    margin: 0;
    width: 18px;
    height: 18px;

    border: 1px solid #000;
    flex-shrink: 0;
  }

  input[type='radio']:hover {
    background-color: color-mix(in srgb, #000 5%, #f2f4ff 100%);
  }
`;

const StyledSvg = styled.svg`
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  fill: #002517;
  pointer-events: none;

  &.checked {
    opacity: 1;
  }
`;

type RadioOptionProps = {
  label: string;
  checked?: boolean;
  onChange?: () => void;
  value: string;
};

const RadioOption = ({
  label,
  onChange,
  checked = false,
  value,
}: RadioOptionProps) => {
  const classes = getClasses({ checked });

  return (
    <RadioWrapper>
      <input type="radio" id={value} checked={checked} onChange={onChange} />
      <StyledSvg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g>
          <rect x="4" y="4" width="10" height="10" rx="2" />
        </g>
      </StyledSvg>
      <Typography as="label" variant="body2" htmlFor={value} margin="none">
        {label}
      </Typography>
    </RadioWrapper>
  );
};

type CheckBoxProps = {
  field: Field;
  keyValue: string;
  label: string;
  partType: PartType;
};

const Checkbox = ({ field, keyValue, label, partType }: CheckBoxProps) => {
  const dispatch = useDispatch();

  const partKey =
    partType === 'coverLetter' ? COVER_LETTER_KEY : TAX_SUMMARY_KEY;

  const handleChange = (checked: boolean) => {
    dispatch(updateTaxDeclarationFormField(partKey, keyValue, checked));
  };

  return (
    <SharedCheckbox
      type="tax"
      checked={booleanValue(field)}
      onChange={handleChange}
      label={label}
      labelVariant="body2"
    />
  );
};

export { Input, Checkbox, Radio, RadioOption };

import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { pdfjs, Document, Page } from 'react-pdf';
import {
  isImageDocument,
  isPdfDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';
import getFileExtension from 'utils/fileExtension';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const Image = styled.img`
  break-inside: avoid;
  display: block;
  max-width: 100%;
  @media print {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ImageHeading = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const PdfPreview = styled(Document)`
  width: 100%;
  @media print {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const PdfPage = styled(Page)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  .react-pdf__Page__textContent {
    overflow: hidden;
  }
  @media print {
    margin-top: 0;
    page-break-inside: avoid;
  }
`;

const PageBreak = styled.a`
  page-break-before: always;
  page-break-after: always;
`;

type DocumentComponentProps = {
  document: {
    name: string;
    url: string;
  };
  pdfPageSize?: {
    height?: number;
    width?: number;
  };
  className?: string;
  hideTitle?: boolean;
  isPrint?: boolean;
  onLoad?: () => void;
};

const DocumentComponent = ({
  document,
  pdfPageSize = {
    width: 784,
  },
  className,
  hideTitle = false,
  isPrint = false,
  onLoad = () => {},
}: DocumentComponentProps): JSX.Element | null => {
  const pagesToLoad = useRef(0);

  const [documentsPages, setDocumentsPages] = useState(0);
  const [isPdfReady, setIsPdfReady] = useState(false);

  const handlePageRender = () => {
    pagesToLoad.current -= 1;
    if (pagesToLoad.current === 0) {
      onLoad();
    }
  };

  const handlePDFLoading = async (pdfInfo) => {
    pagesToLoad.current = pdfInfo.numPages;
    setDocumentsPages(pdfInfo.numPages);
    setIsPdfReady(true);
  };

  const extension = getFileExtension(document.name);

  if (isPrint) {
    return (
      <PageBreak href="https://agoy.se/page-break-marker">Page break</PageBreak>
    );
  }

  if (isImageDocument(extension)) {
    return (
      <>
        {!hideTitle && <ImageHeading> {document.name}</ImageHeading>}
        <Image
          src={document.url}
          alt={document.name}
          onLoad={onLoad}
          onError={onLoad}
        />
      </>
    );
  }

  if (isPdfDocument(extension)) {
    return (
      <>
        {!hideTitle && <ImageHeading> {document.name}</ImageHeading>}
        <PdfPreview
          file={document.url}
          onLoadSuccess={(pdfInfo) => handlePDFLoading(pdfInfo)}
          onLoadError={onLoad}
          className={className}
        >
          {isPdfReady &&
            Array.from(new Array(documentsPages), (el, index) => (
              <PdfPage
                width={pdfPageSize.width}
                height={pdfPageSize.height}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onRenderSuccess={handlePageRender}
                onRenderError={handlePageRender}
                renderAnnotationLayer={false}
                renderForms={false}
                renderTextLayer={false}
              />
            ))}
        </PdfPreview>
      </>
    );
  }

  return null;
};

export default DocumentComponent;

import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';

import Expandable from './Expandable';
import ColumnItem from './ColumnItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
  gap: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${({ theme }) => theme.shadows[4]};
  width: 100%;
`;

const mockedColumns: SpecificationColumnType[] = [
  {
    id: 1,
    contentType: 'TEXT',
    name: 'Description',
  },
  {
    id: 2,
    contentType: 'AMOUNT',
    name: 'Total Cost',
    order: 1,
  },
  {
    id: 3,
    contentType: 'DATE',
    name: 'Start Date',
  },
  {
    id: 4,
    contentType: 'PERCENTAGE',
    name: 'Completion Rate',
    order: 2,
  },
  {
    id: 5,
    contentType: 'TEXT',
    name: 'Project Name',
  },
  {
    id: 6,
    contentType: 'DATE',
    name: 'End Date',
    order: 3,
  },
  {
    id: 7,
    contentType: 'AMOUNT',
    name: 'Discount',
  },
  {
    id: 8,
    contentType: 'TEXT',
    name: 'Notes',
    order: 4,
  },
  {
    id: 9,
    contentType: 'PERCENTAGE',
    name: 'Interest Rate',
  },
  {
    id: 10,
    contentType: 'TEXT',
    name: 'Category',
    order: 5,
  },
];

interface ColumnSelectorProps {
  selectedColumns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  onToggleColumn: (
    column: SpecificationColumnType,
    value: boolean
  ) => Promise<void>;
  onClose: () => void;
}

const ColumnSelector = ({
  selectedColumns,
  rows,
  onToggleColumn,
  onClose,
}: ColumnSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const selectedColumnsId = useMemo(
    () => selectedColumns.map((col) => col.id),
    [selectedColumns]
  );

  const columnsWithData = useMemo(() => {
    const columns: number[] = [];

    rows.forEach((row) =>
      row.cells.forEach((cell) => {
        if (cell.value && !columns.includes(cell.columnId)) {
          columns.push(cell.columnId);
        }
      })
    );

    return columns;
  }, [rows]);

  return (
    <>
      <Container>
        <Expandable
          title={formatMessage({ id: 'hidden.specification.manualColumns' })}
          defaultExpanded
        >
          {mockedColumns.map((column) => (
            <ColumnItem
              key={column.id}
              column={column}
              withData={columnsWithData.includes(column.id)}
              checked={selectedColumnsId.includes(column.id)}
              onCheck={onToggleColumn}
            />
          ))}
        </Expandable>
      </Container>

      <ActionButtons>
        <Button
          label={formatMessage({ id: 'hidden.specification.close' })}
          size="medium"
          onClick={onClose}
        />
      </ActionButtons>
    </>
  );
};

export default ColumnSelector;

import React, { useMemo } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'core-js/full/promise/with-resolvers';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

type PdfPreviewProps = {
  src: string;
};

const PdfPreview = ({ src }: PdfPreviewProps): JSX.Element => {
  const fileSrc = useMemo(() => ({ url: src }), [src]);
  return (
    <Document file={fileSrc}>
      <Page
        renderAnnotationLayer={false}
        renderForms={false}
        renderTextLayer={false}
        width={248}
        pageNumber={1}
      />
    </Document>
  );
};

export default PdfPreview;

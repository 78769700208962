import { value } from '@agoy/document';
import { getAnnualConfig, getIndividualTaxConfig } from '@agoy/common';
import { PersonMetaData, TaxDeclarationFormDefinition } from '../../types';
import { mapFormId, toInitial, toStructure } from '../forms-util';
import tables from './tables';

const configTaxSummary = (
  form: TaxDeclarationFormDefinition,
  customer: PersonMetaData,
  financialYearStart: string,
  financialYearEnd: string
) => {
  const year = parseInt(financialYearEnd.slice(0, 4));
  const annualConfig = getAnnualConfig(year);
  const individualTaxConfig = getIndividualTaxConfig(year);

  if (!annualConfig) {
    throw new Error('Failed to get annual configuration for tax summary');
  }

  const TaxSummary = mapFormId(form, 'TS');
  const initial = {
    TS: {
      partType: 'taxSummary' as const,
      id: TaxSummary,
      fields: {
        name: value(`${customer.firstName} ${customer.lastName}`),
        personalNumber: value(`${customer.personNumber}`),
        date: value(''),
        financialYear: value(''),
        taxYear: value(''),
        municipality: value(''),
        burialFeeRate: value(),
        municipalTaxRate: value(),
        religiousCommunitiesFee: value(),
        taxFreeAllowance: value(),
        ...tables(annualConfig, individualTaxConfig),
      },
      sru: {},
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configTaxSummary;

import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Card, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import config from '_shared/services/config';
import { PrivatePersonWithManager } from '_person/_types/person';
import { getOrganisationMembers } from '_organization/redux/actions';
import { formatPersonalNumber } from '@agoy/common';
import { useSelector } from 'redux/reducers';

import InfoField, { Validation } from './_organisms/InfoField';
import PersonEditFormHeader from './_organisms/PersonEditFormHeader';
import ContactPersonPicker from './_organisms/ContactPersonPicker';
import PersonNumberField from './PersonNumberField';

const PersonEditFormBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)}px;
`;

const FormSection = styled.div`
  width: 520px;
`;

const FormCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(2)}px;
  row-gap: ${(props) => props.theme.spacing(2)}px;
`;

type PersonDetailsViewProps = {
  person: PrivatePersonWithManager;
  onEdit: (updatedPerson: PrivatePersonWithManager) => void;
};

const isFortnoxMode = config.whiteLabelUI === 'fortnox';

const Breadcrumb = ({ children }) => (
  <Typography variant="subtitle1">{children}</Typography>
);

const PersonDetailsView = ({
  person,
  onEdit,
}: PersonDetailsViewProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const disableEdit = isFortnoxMode;

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: person,
  });

  const [isEditing, setIsEditing] = useState(false);

  const orgMembers = useSelector((state) => state.organisation.users);

  const organisationMembers = orgMembers.map(({ email }) => ({
    value: email,
    label: email,
  }));

  const text = (key: string) => formatMessage({ id: `person.form.${key}` });

  const handleOnSubmit = async (formData: PrivatePersonWithManager) => {
    const updatedManager = orgMembers.find((m) => m.email === formData.manager);
    // we update the managerId to the newly selected manager
    const updatedPerson = updatedManager
      ? { ...formData, managerId: updatedManager?.userId }
      : formData;

    onEdit(updatedPerson);
    setIsEditing(false);
  };

  useEffect(() => {
    if (orgMembers.length > 0) return;
    dispatch(getOrganisationMembers(true));
  }, [orgMembers, dispatch]);

  useEffect(() => {
    if (orgMembers.length > 0) {
      const manager = orgMembers.find((m) => m.userId === person.managerId);
      setValue('manager', manager?.email);
    }
  }, [orgMembers, person, setValue]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Breadcrumbs>
        <Breadcrumb>{person.firstName}</Breadcrumb>
        <Breadcrumb>{text('editing')}</Breadcrumb>
      </Breadcrumbs>

      <PersonEditFormHeader
        name={`${person.firstName} ${person.lastName}`}
        personNumber={person.personNumber}
        isEditing={isEditing}
        toggleEditing={() => setIsEditing(!isEditing)}
        onSave={handleSubmit(handleOnSubmit)}
      >
        <InfoField
          {...register('firstName', { required: true })}
          fieldName="firstName"
          fieldError={errors.firstName}
          isEditing={isEditing}
          readOnlyValue={person.firstName}
          disabled={disableEdit}
          fullWidth
        />
        <InfoField
          {...register('lastName', { required: true })}
          fieldName="lastName"
          fieldError={errors.lastName}
          isEditing={isEditing}
          readOnlyValue={person.lastName}
          disabled={disableEdit}
          fullWidth
        />
      </PersonEditFormHeader>

      <PersonEditFormBody>
        <FormSection>
          <Typography variant="h3">{text('contact')}</Typography>
          <FormCard>
            <InfoField
              {...register('taxAddress.street', { required: true })}
              fieldName="taxAddress.street"
              fieldError={errors['taxAddress.street']}
              readOnlyValue={person.taxAddress.street}
              labelPlacement="left"
              disabled={disableEdit}
              isEditing={isEditing}
              fullWidth
            />
            <InfoField
              {...register('taxAddress.zipCode', { required: true })}
              fieldName="taxAddress.zipCode"
              fieldError={errors['taxAddress.zipCode']}
              readOnlyValue={person.taxAddress.zipCode}
              labelPlacement="left"
              disabled={disableEdit}
              isEditing={isEditing}
              fullWidth
            />
            <InfoField
              {...register('taxAddress.city', { required: true })}
              fieldName="taxAddress.city"
              fieldError={errors['taxAddress.city']}
              readOnlyValue={person.taxAddress.city}
              labelPlacement="left"
              isEditing={isEditing}
              disabled={disableEdit}
              fullWidth
            />
            <InfoField
              {...register('taxAddress.country', { required: true })}
              fieldName="taxAddress.country"
              fieldError={errors['taxAddress.country']}
              readOnlyValue={person.taxAddress.country}
              labelPlacement="left"
              disabled={disableEdit}
              isEditing={isEditing}
              fullWidth
            />
            <ContactPersonPicker
              {...register('manager')}
              getValues={getValues}
              labelPlacement="left"
              control={control}
              fieldError={errors.manager}
              list={organisationMembers}
              isEditing={isEditing}
              fullWidth
            />
          </FormCard>
        </FormSection>

        <FormSection>
          <Typography variant="h3">{text('client')}</Typography>
          <FormCard>
            <PersonNumberField
              {...register('personNumber', {
                required: true,
                ...Validation.PersonalNumber,
              })}
              fieldName="personNumber"
              fieldError={errors.personNumber}
              readOnlyValue={formatPersonalNumber(
                person.personNumber,
                true,
                true
              )}
              labelPlacement="left"
              disabled={disableEdit}
              isEditing={isEditing}
              fullWidth
            />
            <InfoField
              {...register('contactEmail', Validation.Email)}
              fieldName="contactEmail"
              fieldError={errors.contactEmail}
              readOnlyValue={person.contactEmail}
              labelPlacement="left"
              disabled={disableEdit}
              isEditing={isEditing}
              fullWidth
            />
            <InfoField
              {...register('contactPhoneNumber', Validation.PhoneNumber)}
              fieldName="contactPhoneNumber"
              fieldError={errors.contactPhoneNumber}
              readOnlyValue={person.contactPhoneNumber}
              labelPlacement="left"
              disabled={disableEdit}
              isEditing={isEditing}
              fullWidth
            />
          </FormCard>
        </FormSection>
      </PersonEditFormBody>
    </form>
  );
};

export default PersonDetailsView;

import { Municipality } from '@agoy/api-sdk-core';
import { getFinancialYearEnd } from '@agoy/dates';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';

type Municipalities = Array<Municipality>;

const useMunicipalities = () => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();
  const [municipalities, setMunicipalities] = useState<Municipalities | null>(
    null
  );
  const { currentFinancialYear } = useSelector((state) => state.customerView);

  useEffect(() => {
    const fetchMunicipalities = async () => {
      try {
        const currentYear = getFinancialYearEnd(currentFinancialYear ?? '');

        const response = await asResultClass(
          sdk.getMunicipalities({ year: currentYear })
        );
        if (response.ok) {
          setMunicipalities(response.val.municipalities);
        }
      } catch (err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    };

    fetchMunicipalities();
  }, [sdk, dispatch, currentFinancialYear]);

  return { municipalities };
};

export default useMunicipalities;

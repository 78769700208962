import React from 'react';
import styled from '@emotion/styled';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import { LogoOrName } from './Components';

const HeaderContainer = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing(10)}px;
  grid-template-columns: 1fr 1fr;
`;

interface HeaderProps {
  title: string;
  subTitle: string;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  orgName: string | undefined;
  children: React.ReactNode;
}

const Header = ({
  title,
  postalCode,
  subTitle,
  city,
  orgName,
  children,
}: HeaderProps) => {
  return (
    <HeaderContainer>
      <div>
        <Typography variant="h2" as="h1" margin="none">
          {title}
        </Typography>
        <Typography variant="h3" margin="none">
          {subTitle}
        </Typography>
        {children}
      </div>
      <LogoOrName orgName={orgName} postalCode={postalCode} city={city} />
    </HeaderContainer>
  );
};

export default Header;

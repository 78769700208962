import React from 'react';
import { Grid } from '@material-ui/core';
import { FieldError, RegisterOptions } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SimplifiedTextfield } from '_shared/components/Inputs';
import FormError from '_shared/components/Inputs/FormError';
import { PrivatePerson } from '_person/_types/person';
import { isValidPersonalNumber } from '@agoy/common';

export type ValidationTypes = {
  [key: string]: RegisterOptions;
};

export const Validation: ValidationTypes = {
  PersonalNumber: {
    validate: (number) => isValidPersonalNumber(number),
    minLength: 10,
    pattern: /^(18|19|20|)?(\d{6})[-–+]?(\d{4})$/g,
  },
  Email: { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ },
  PhoneNumber: { pattern: /0([-\s]?\d){6,10}/ },
};

/**
 * NestedKeyOf will give us typed fields even for the nested keys
 * such as postaddress.city etc..
 */
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & string]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & string];

export type InfoFieldProps = {
  fieldName: NestedKeyOf<PrivatePerson>;
  disabled?: boolean;
  fieldError?: FieldError;
  placeholder?: string;
  readOnlyValue?: string;
  isEditing?: boolean;
  labelPlacement?: 'top' | 'left';
  fullWidth?: boolean;
};

const InfoField = React.forwardRef((props: InfoFieldProps, ref) => {
  const { formatMessage } = useIntl();
  const {
    fieldName,
    isEditing = true,
    readOnlyValue,
    labelPlacement = 'top',
    fieldError,
    ...rest
  } = props;

  const getString = (field: string) => {
    return formatMessage({ id: `person.form.${field}` });
  };

  const getErrorMessage = () => {
    const validationMessages = {
      validate: getString(`${fieldName}.wrongFormat`),
      minLength: getString(`${fieldName}.wrongFormat`),
      pattern: getString(`${fieldName}.wrongFormat`),
      required: getString('required'),
    };
    return fieldError ? validationMessages[fieldError.type] : '';
  };

  return (
    <Grid item sm={rest.fullWidth ? 10 : 6} xs={12}>
      <SimplifiedTextfield
        {...rest}
        label={getString(fieldName)}
        value={!isEditing ? readOnlyValue : undefined}
        inputRef={ref}
        labelPlacement={labelPlacement}
        editing={isEditing}
      />
      <FormError>{getErrorMessage()}</FormError>
    </Grid>
  );
});

export default InfoField;

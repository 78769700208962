/* eslint-disable no-shadow */
import React from 'react';

import { InputData } from '_reconciliation/types';
import { Period } from '@agoy/api-sdk-core';

import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import AccrualFundView from '../AccrualFundView';
import EventView from '../EventView';
import LegacySpecificationView from '../LegacySpecificationView';
import SpecificationView from '../SpecificationView';
import SkatteverketIntegrationView from '../SkatteverketIntegrationView';
import TransactionAnalysis from '../TransactionAnalysisView';
import { CustomerInvoicesView } from '../CustomerInvoicesView';
import { SupplierInvoicesView } from '../SupplierInvoicesView';
import InventoryView from '../InventoryView';
import CashView from '../CashView';
import { SemesterskuldView } from '../SemesterskuldView';
import { LagerjusteringView } from '../LagerjusteringView';
import { InventoryStockView } from '../InventoryStockView';
import { onUserInputRoutineCallbackFunction } from '../types';

export const isAccrualFundAccount = (accountNumber: number): boolean => {
  return accountNumber >= 2110 && accountNumber <= 2129;
};

export const isCustomerInvoicesActive = (accountNumber: number): boolean => {
  return accountNumber === 1510;
};

export const isSupplierInvoicesActive = (accountNumber: number): boolean => {
  return accountNumber === 2440;
};

export const isTaxRelatedAccount = (accountNumber: number): boolean => {
  return [1630, 1650, 2510, 2514, 2518, 2650, 2710, 2711, 2730, 2731].includes(
    accountNumber
  );
};

export const isCashRelatedAccount = (accountNumber: number): boolean => {
  return accountNumber >= 1900 && accountNumber <= 1919;
};

export const isInventoryRelatedAccount = (accountNumber: number): boolean => {
  return accountNumber >= 1400 && accountNumber <= 1499;
};

export const isStockAdjustmentAccount = (accountNumber: number): boolean => {
  return accountNumber >= 1400 && accountNumber <= 1469;
};

export const isInventoryStockAccount = (accountNumber: number): boolean => {
  return accountNumber >= 1400 && accountNumber <= 1469;
};

export const isVacationDebtRelatedAccount = (
  accountNumber: number
): boolean => {
  return (
    (accountNumber >= 2920 && accountNumber <= 2929) ||
    [2940, 2941].includes(accountNumber)
  );
};

export interface TabContentProps {
  clientId: string;
  userData: InputData;
  accountNumber: string;
  accountName: string;
  groupId: string;
  period: Period;
  periods?: Period[];
  onUserInputRoutineCallback: onUserInputRoutineCallbackFunction;
  onCloseCallback: any;
}

export interface ConfigTab {
  id: string; //  used to navigate to specific tabs with url. Ex: `accrual-funds`
  titleId: string;
  periodTypes: (
    | 'financialYear'
    | 'month'
    | 'quarter'
    | 'period'
    | 'dead'
    | 'yearEnd'
  )[];
  component: (TabContentProps: TabContentProps) => React.ReactNode;
  isActive?: (accountNumber: number) => boolean;
}

/**
 * All period types, except for 'dead' which has only a few tabs.
 */
const ALL_PERIOD_TYPES: ConfigTab['periodTypes'] = [
  'financialYear',
  'month',
  'quarter',
  'period',
  'yearEnd',
];

export const configTabs = ((): Array<ConfigTab> => {
  const isNewSpecificationsEnabled = activeFeatureFlags.get(
    'feature_newSpecifications'
  );

  const configTabsList: Array<ConfigTab> = [
    {
      id: 'event-view',
      titleId: 'hidden.event.view',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({
        accountNumber,
        accountName,
        groupId,
        userData,
        onUserInputRoutineCallback,
      }: TabContentProps) => (
        <EventView
          account={accountNumber}
          accountName={accountName}
          groupId={groupId}
          userData={userData}
          onUserInputRoutineCallback={onUserInputRoutineCallback}
        />
      ),
    },
    {
      id: 'specification',
      titleId: 'hidden.specification.view',
      periodTypes: [...ALL_PERIOD_TYPES, 'dead'],
      component: ({ userData, accountNumber, accountName }: TabContentProps) =>
        isNewSpecificationsEnabled ? (
          <SpecificationView
            userData={userData}
            accountNumber={accountNumber}
            accountName={accountName}
          />
        ) : (
          <LegacySpecificationView
            userData={userData}
            accountNumber={accountNumber}
            accountName={accountName}
          />
        ),
    },
    {
      id: 'transaction-analysis',
      titleId: 'hidden.transactionanalysis.view',
      periodTypes: [...ALL_PERIOD_TYPES, 'dead'],
      component: ({ userData, accountNumber }: TabContentProps) => (
        <TransactionAnalysis userData={userData} account={accountNumber} />
      ),
    },
    {
      id: 'accrual-funds',
      titleId: 'hidden.accrualfunds.view',
      periodTypes: ['period', 'financialYear', 'yearEnd'],
      component: () => <AccrualFundView />,
      isActive: isAccrualFundAccount,
    },
    {
      id: 'tax-agency-integration',
      titleId: 'hidden.taxagencyintegration.view',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <SkatteverketIntegrationView
          userData={userData}
          accountNumber={accountNumber}
        />
      ),
      isActive: isTaxRelatedAccount,
    },
    {
      id: 'customer-invoices',
      titleId: 'hidden.customerinvoices.view',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <CustomerInvoicesView userData={userData} account={accountNumber} />
      ),
      isActive: isCustomerInvoicesActive,
    },
    {
      id: 'supplier-invoices',
      titleId: 'hidden.supplierinvoices.view',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <SupplierInvoicesView userData={userData} account={accountNumber} />
      ),
      isActive: isSupplierInvoicesActive,
    },
    {
      id: 'cash-view',
      titleId: 'hidden.cash.title',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <CashView userData={userData} accountNumber={accountNumber} />
      ),
      isActive: isCashRelatedAccount,
    },
    {
      id: 'inventory-view',
      titleId: 'hidden.inventory.title',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <InventoryView userData={userData} accountNumber={accountNumber} />
      ),
      isActive: isInventoryRelatedAccount,
    },
    {
      id: 'semesterskuld-view',
      titleId: 'hidden.semesterskuld.title',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData }: TabContentProps) => (
        <SemesterskuldView userData={userData} />
      ),
      isActive: isVacationDebtRelatedAccount,
    },
    {
      id: 'lagerjustering-view',
      titleId: 'hidden.lagerjustering.title',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <LagerjusteringView userData={userData} accountNumber={accountNumber} />
      ),
      isActive: isStockAdjustmentAccount,
    },
    {
      id: 'inventory-stock-view',
      titleId: 'hidden.inventoryStock.title',
      periodTypes: ALL_PERIOD_TYPES,
      component: ({ userData, accountNumber }: TabContentProps) => (
        <InventoryStockView userData={userData} accountNumber={accountNumber} />
      ),
      isActive: isInventoryStockAccount,
    },
  ];

  const featureFlaggedTabs: Array<ConfigTab> = [];

  return [...configTabsList, ...featureFlaggedTabs];
})();

export const getActiveTabsDeps =
  ({ configTabs }: { configTabs: Array<ConfigTab> }) =>
  (
    accountNumber: number,
    periodType: ConfigTab['periodTypes'][number]
  ): Array<ConfigTab> => {
    return configTabs
      .filter((tab) => tab.periodTypes.includes(periodType))
      .filter((tab) => (tab.isActive ? tab.isActive(accountNumber) : true));
  };

export const getActiveTabs = getActiveTabsDeps({ configTabs });

import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import PersonalNumber from '_shared/components/PersonalNumber';
import Typography from '_shared/components/Typography/Typography';
import InfoField, { InfoFieldProps } from './_organisms/InfoField';

const Row = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
`;

const PersonNumberField = React.forwardRef((props: InfoFieldProps, ref) => {
  const { formatMessage } = useIntl();

  const { isEditing = true, readOnlyValue, fieldName } = props;

  if (isEditing) {
    return <InfoField {...props} ref={ref} />;
  }

  return (
    <Row>
      <Typography margin="none" color="placeholder">
        {formatMessage({ id: `person.form.${fieldName}` })}
      </Typography>
      <PersonalNumber value={readOnlyValue} />
    </Row>
  );
});

export default PersonNumberField;

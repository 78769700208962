import React, { useContext, useState } from 'react';
import {
  AgoyTable,
  AgoyTableColumn,
  AgoyTableRow,
  booleanValue,
  numberValue,
  stringValue,
} from '@agoy/document';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import {
  BaseRow,
  Table,
  TableBody,
  TableBodyCell as BaseTableBodyCell,
  TableHead,
  TableHeadCell,
} from '@agoy/annual-report-view/src/UI/HierarchicalPrintTable/v2/TableComponents';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import styled from '@emotion/styled';
import When from '_shared/components/When/When';
import Button from '_shared/components/Buttons/Button';
import Input from '../../Input/Input';
import TaxSummaryTableEditModal from './TaxSummaryTableEditModal';

const TableBodyCell = styled(BaseTableBodyCell)`
  vertical-align: middle;
  height: ${({ theme }) => theme.spacing(4)}px;
  padding: 0 ${({ theme }) => theme.spacing(0.5)}px;

  &.sum {
    font-weight: 700;
  }

  &.sign {
    text-align: center;
  }

  &.value {
    padding: ${({ theme }) =>
      `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`};
  }
`;

const getColumnSize = (columnId: string) => {
  if (columnId === 'sign') return '32px';
  if (columnId === 'value') return '200px';
};

const isRowType = (row, type) => row.type === type;
const isUndefinedValue = (value) => value === undefined;

interface TaxSummaryTableRowProps {
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  baseId: string;
}

const TaxSummaryTableRow = ({
  row,
  columns,
  baseId,
}: TaxSummaryTableRowProps) => {
  const subRows = row.rows ?? [];
  const rowType = row.type ?? 'row';

  // We check if we are in print context here
  const { state } = useContext(PrintStateContext);
  const isPrint = !!state?.additionalPages;

  const value = numberValue(row.cells?.value);

  // Hide rows that have value undefined in print
  if (
    isPrint &&
    isUndefinedValue(value) &&
    (isRowType(row, 'row') || isRowType(row, 'sum'))
  ) {
    return null;
  }

  return (
    <>
      {rowType !== 'hidden' && (
        <BaseRow className={rowType}>
          {columns.map((col) => {
            const cell = row.cells?.[col.id];
            const key = `${row.id}.${col.id}`;

            if (!cell) {
              return <TableBodyCell key={key} />;
            }

            const cellNumberValue = numberValue(cell);

            return (
              <TableBodyCell
                key={key}
                className={`${rowType} ${col.id} ${col.dataType ?? 'text'}`}
              >
                <When isTrue={col.id === 'label'}>
                  <Typography
                    textStyle={rowType === 'sum' ? 'bold' : 'regular'}
                    margin="none"
                  >
                    {stringValue(cell)}
                  </Typography>
                </When>
                <When isTrue={col.id === 'sign'}>
                  <Typography
                    margin="none"
                    variant="h3"
                    textStyle="regular"
                    as="p"
                  >
                    <When isTrue={rowType !== 'sum'} fallback="=">
                      {cellNumberValue && (cellNumberValue < 0 ? '-' : '+')}
                    </When>
                  </Typography>
                </When>
                <When isTrue={col.id === 'value'}>
                  <Input partId="TS" fieldId={`${baseId}.${key}`} cell={cell} />
                </When>
              </TableBodyCell>
            );
          })}
        </BaseRow>
      )}
      {subRows
        .filter((subRow) => booleanValue(subRow.active))
        .map((subRow) => (
          <React.Fragment key={subRow.id}>
            <TaxSummaryTableRow
              row={subRow}
              columns={columns}
              baseId={`${baseId}.${row.id}`}
            />
          </React.Fragment>
        ))}
    </>
  );
};

interface TaxSummaryTableProps {
  tableId: string;
  table: AgoyTable;
}

const TaxSummaryTable = ({ tableId, table }: TaxSummaryTableProps) => {
  const [rowsEditOpen, setRowsEditOpen] = useState(false);

  const { columns, rows: tableRows } = table;
  const rows = tableRows.map((row) => ({
    ...row,
    rows: row.rows?.filter((subRow) => subRow.active),
  }));

  return (
    <>
      <Table>
        <TableHead>
          <BaseRow className="table-header">
            {columns.map((col) => (
              <TableHeadCell
                key={col.id}
                className={col.id === 'value' ? 'numeric' : 'text'}
                cellWidth={getColumnSize(col.id)}
              >
                <When
                  isTrue={col.id === 'value'}
                  fallback={
                    <Typography variant="h4" as="div" margin="none">
                      {col.label}
                    </Typography>
                  }
                >
                  <Button
                    label="Redigera tabell"
                    variant="text"
                    size="small"
                    onClick={() => setRowsEditOpen(true)}
                  />
                </When>
              </TableHeadCell>
            ))}
          </BaseRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TaxSummaryTableRow
              key={row.id}
              columns={columns}
              row={row}
              baseId={tableId}
            />
          ))}
        </TableBody>
      </Table>

      {rowsEditOpen && (
        <TaxSummaryTableEditModal
          tableId={tableId}
          table={table}
          open={rowsEditOpen}
          setOpen={setRowsEditOpen}
        />
      )}
    </>
  );
};

export default TaxSummaryTable;

import React from 'react';
import styled from '@emotion/styled';
import { CoverLetterFields } from '@agoy/tax-declaration-document';
import {
  CL,
  TaxDeclarationHelp,
} from '@agoy/tax-declaration-document/src/types/help';
import { AgoyDocumentNode, booleanValue, Field } from '@agoy/document';
import { getClasses } from '@agoy/common';

import Spacer from '@agoy/annual-report-view/src/UI/helpers/Spacer';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import When from '_shared/components/When/When';

import { TaxTableWrapper, Paper } from '../Common/Components';

import {
  SigningOFIncomeDeclaration,
  ToSkatteverket,
  PaymentTable,
} from './Components';
import { Input, PartType } from '../Common/Input';
import Header from '../Common/Header';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const Container = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing(0.5)}px;

  @media print {
    page-break-inside: avoid;
  }

  &.both {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    grid-template-rows: auto 1fr auto;
  }

  .narrowInput {
    width: 95%;
  }
`;

const AvoidPageBreak = styled.div`
  @media print {
    page-break-inside: avoid;
  }
`;

type RowProps = {
  title: string;
  keyValue: string;
  field: Field;
  partType: PartType;
};

const Row = ({ title, keyValue, field, partType }: RowProps) => {
  return (
    <tr>
      <td width="75%">
        <Typography margin="none" variant="body2">
          {title}
        </Typography>
      </td>
      <td>
        <Input field={field} keyValue={keyValue} partType={partType} />
      </td>
    </tr>
  );
};

type TaxTableProps = {
  estimatedFinalTax: Field;
  estimatedTaxRefund: Field;
  fTax: Field;
};

const TaxTable = ({
  estimatedFinalTax,
  estimatedTaxRefund,
  fTax,
}: TaxTableProps) => {
  return (
    <TaxTableWrapper>
      <Row
        title="Beräknad slutlig skatt"
        keyValue="estimatedFinalTax"
        field={estimatedFinalTax}
        partType="coverLetter"
      />
      <Row
        title="Beräknad skatteåterbäring"
        keyValue="estimatedTaxRefund"
        field={estimatedTaxRefund}
        partType="coverLetter"
      />
      <Row
        title="F-skatt"
        keyValue="fTax"
        field={fTax}
        partType="coverLetter"
      />
    </TaxTableWrapper>
  );
};

const isCoverLetterHelp = (
  help: TaxDeclarationHelp
): help is AgoyDocumentNode<typeof CL> => help._type === 'CL';

type CoverLetterProps = {
  fields: CoverLetterFields;
  help: TaxDeclarationHelp | undefined;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  orgName: string | undefined;
};

const CoverLetter = ({
  fields,
  help,
  postalCode,
  city,
  orgName,
}: CoverLetterProps) => {
  if (!help || !isCoverLetterHelp(help)) {
    return null;
  }

  const isBothSignatureAndToSkatteverket =
    booleanValue(help.signatureRadio) &&
    booleanValue(help.toSkatteverketCheckbox) &&
    booleanValue(help.signature) &&
    booleanValue(help.toSkatteverket);

  const classes = getClasses({
    both: isBothSignatureAndToSkatteverket,
    narrowInput: isBothSignatureAndToSkatteverket,
  });

  return (
    <Paper>
      <Header
        title="Följebrev"
        subTitle="Till inkomstdeklarationen"
        postalCode={postalCode}
        city={city}
        orgName={orgName}
      >
        <>
          <Input
            field={fields.name}
            label="Namn"
            keyValue="name"
            partType="coverLetter"
          />
          <Spacer size={1} />
          <InputGroup>
            <Input
              field={fields.personalNumber}
              label="Person-/Organisationsnummer"
              keyValue="personalNumber"
              partType="coverLetter"
            />
            <Input
              field={fields.taxYear}
              label="Beskattningsår"
              keyValue="taxYear"
              partType="coverLetter"
            />
          </InputGroup>
        </>
      </Header>

      <BodyContainer>
        <When isTrue={booleanValue(help.mission)}>
          <Spacer size={3} />
          <When isTrue={booleanValue(help.missionTitle)}>
            <Input
              field={fields.missionTitle}
              keyValue="missionTitle"
              title
              partType="coverLetter"
            />
          </When>
          <Spacer size={0.5} />
          <Input
            field={fields.missionText}
            keyValue="missionText"
            multiline
            partType="coverLetter"
          />
        </When>
        <Spacer size={3} />
        <When isTrue={booleanValue(help.responsibility)}>
          <When isTrue={booleanValue(help.responsibilityTitle)}>
            <Input
              field={fields.responsibilityTitle}
              keyValue="responsibilityTitle"
              title
              partType="coverLetter"
            />
          </When>
          <Spacer size={0.5} />
          <Input
            field={fields.responsibilityText}
            keyValue="responsibilityText"
            multiline
            partType="coverLetter"
          />
          <Spacer size={3} />
        </When>

        <Container className={classes}>
          <When isTrue={booleanValue(help.signature)}>
            <When
              isTrue={booleanValue(help.signatureTitle)}
              fallback={<span />}
            >
              <Input
                className={classes}
                field={fields.signatureTitle}
                keyValue="signatureTitle"
                title
                partType="coverLetter"
              />
            </When>
            <When
              isTrue={booleanValue(help.signatureRadio)}
              fallback={<span />}
            >
              <SigningOFIncomeDeclaration
                bothOn={isBothSignatureAndToSkatteverket}
                signatureOfIncomeDeclaration={
                  fields.signatureOfIncomeDeclaration
                }
              />
            </When>
            <When isTrue={booleanValue(help.signatureText)} fallback={<span />}>
              <Input
                multiline
                field={fields.signatureText}
                keyValue="signatureText"
                className={classes}
                partType="coverLetter"
              />
            </When>
          </When>
          <When
            isTrue={
              !booleanValue(help.signatureRadio) ||
              !booleanValue(help.toSkatteverketCheckbox)
            }
          >
            <Spacer size={1} />
          </When>
          <When isTrue={booleanValue(help.toSkatteverket)}>
            <When
              isTrue={booleanValue(help.toSkatteverketTitle)}
              fallback={<span />}
            >
              <Input
                field={fields.toSkatteverketTitle}
                keyValue="toSkatteverketTitle"
                title
                partType="coverLetter"
              />
            </When>
            <When
              isTrue={booleanValue(help.toSkatteverketCheckbox)}
              fallback={<span />}
            >
              <ToSkatteverket
                checkboxes={[
                  fields.toSkatteverketCheckbox1,
                  fields.toSkatteverketCheckbox2,
                  fields.toSkatteverketCheckbox3,
                  fields.toSkatteverketCheckbox4,
                ]}
              />
            </When>
            <When
              isTrue={booleanValue(help.toSkatteverketText)}
              fallback={<span />}
            >
              <Input
                multiline
                field={fields.toSkatteverketText}
                keyValue="toSkatteverketText"
                partType="coverLetter"
              />
            </When>
          </When>
        </Container>

        <AvoidPageBreak>
          <When isTrue={booleanValue(help.taxCalculation)}>
            <Spacer size={2} />
            <When isTrue={booleanValue(help.taxCalculationTitle)}>
              <Input
                field={fields.taxCalculationTitle}
                keyValue="taxCalculationTitle"
                title
                partType="coverLetter"
              />
              <Spacer size={0.5} />
            </When>
            <When isTrue={booleanValue(help.taxCalculationText)}>
              <Input
                field={fields.taxCalculationText}
                keyValue="taxCalculationText"
                multiline
                partType="coverLetter"
              />
            </When>
            <When isTrue={booleanValue(help.taxCalculationTable)}>
              <TaxTable
                estimatedFinalTax={fields.estimatedFinalTax}
                estimatedTaxRefund={fields.estimatedTaxRefund}
                fTax={fields.fTax}
              />
            </When>
          </When>
        </AvoidPageBreak>

        <AvoidPageBreak>
          <When isTrue={booleanValue(help.paymentToSkatteverket)}>
            <Spacer size={3} />
            <When isTrue={booleanValue(help.paymentToSkatteverketTitle)}>
              <Input
                field={fields.paymentToSkatteverketTitle}
                keyValue="paymentToSkatteverketTitle"
                title
                partType="coverLetter"
              />
              <Spacer size={0.5} />
            </When>
            <When isTrue={booleanValue(help.paymentToSkatteverketText)}>
              <Input
                field={fields.paymentToSkatteverketText}
                keyValue="paymentToSkatteverketText"
                multiline
                partType="coverLetter"
              />
            </When>
            <When isTrue={booleanValue(help.paymentToSkatteverketTable)}>
              <PaymentTable
                paymentToSkatteverketAmount={fields.paymentToSkatteverketAmount}
                paymentToSkatteverketDate={fields.paymentToSkatteverketDate}
              />
            </When>
          </When>
        </AvoidPageBreak>

        <AvoidPageBreak>
          <When isTrue={booleanValue(help.comment)}>
            <Spacer size={3} />
            <When isTrue={booleanValue(help.commentTitle)}>
              <Input
                field={fields.commentTitle}
                keyValue="commentTitle"
                title
                partType="coverLetter"
              />
              <Spacer size={0.5} />
            </When>
            <Input
              field={fields.commentText}
              keyValue="commentText"
              multiline
              partType="coverLetter"
            />
          </When>
        </AvoidPageBreak>
      </BodyContainer>
    </Paper>
  );
};

export default CoverLetter;

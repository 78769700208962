import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import CheckIcon from '@material-ui/icons/Check';
import { getClasses } from '@agoy/common';
import { ButtonProps } from './Button.types';
import LoadingLogo from '../LoadingLogo';

const ButtonElement = styled('button', {
  shouldForwardProp: isPropValid,
})<{ color: 'primary' | 'danger'; textColor: 'default' | 'white' }>`
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  box-sizing: border-box;
  width: max-content;

  border-radius: 20px;
  border: 2px solid;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Exo 2';
  cursor: pointer;

  &.large {
    height: ${({ theme }) => theme.spacing(5)}px;
    padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(3)}px`};
  }

  &.medium {
    height: ${({ theme }) => theme.spacing(4)}px;
    padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(2)}px`};
  }

  &.small {
    height: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
    line-height: 18px;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &.contained {
    color: white;
    border-color: ${({ theme, color }) => theme.palette.button[color].main};
    background-color: ${({ theme, color }) => theme.palette.button[color].main};

    :hover {
      background-color: ${({ theme, color }) =>
        theme.palette.button[color].hover};
      border-color: ${({ theme, color }) => theme.palette.button[color].hover};
    }

    &.disabled {
      background-color: ${({ theme }) => theme.palette.button.disabled};
      border-color: ${({ theme }) => theme.palette.button.disabled};
    }
  }

  &.outlined {
    color: ${({ theme, color }) => theme.palette.button[color].main};
    border-color: ${({ theme, color }) => theme.palette.button[color].main};
    background-color: transparent;

    :hover {
      color: ${({ theme, color }) => theme.palette.button[color].hover};
      border-color: ${({ theme, color }) => theme.palette.button[color].hover};
    }

    &.disabled {
      color: ${({ theme }) => theme.palette.button.disabled};
      border-color: ${({ theme }) => theme.palette.button.disabled};
    }
  }

  &.text {
    color: ${({ theme, textColor, color }) =>
      textColor === 'default'
        ? theme.palette.button[color].main
        : theme.palette.common.white};
    border-color: transparent;
    background-color: transparent;

    :hover {
      color: ${({ theme, color }) => theme.palette.button[color].hover};
      background-color: ${({ theme }) => theme.palette.button.text.hover};
    }

    &.disabled {
      color: ${({ theme }) => theme.palette.button.disabled};
    }

    &.large {
      padding-left: ${({ theme }) => theme.spacing(2)}px;
      padding-right: ${({ theme }) => theme.spacing(2)}px;
    }

    &.medium,
    &.small {
      padding-left: ${({ theme }) => theme.spacing(1)}px;
      padding-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  &.underlined {
    color: ${({ theme, textColor, color }) =>
      textColor === 'default'
        ? theme.palette.button.underlined[color].main
        : theme.palette.common.white};
    border-color: transparent;
    background-color: transparent;
    text-decoration: underline;
    line-height: normal;
    font-weight: 400;

    :hover {
      color: ${({ theme, color, textColor }) =>
        textColor === 'default'
          ? theme.palette.button.underlined[color].hover
          : theme.palette.common.white};
    }

    &.disabled {
      color: ${({ theme }) => theme.palette.button.disabled};
    }

    &.large {
      padding-left: ${({ theme }) => theme.spacing(2)}px;
      padding-right: ${({ theme }) => theme.spacing(2)}px;
    }

    &.medium {
      padding-left: ${({ theme }) => theme.spacing(1)}px;
      padding-right: ${({ theme }) => theme.spacing(1)}px;
    }

    &.small {
      padding: 0;
    }

    &.loading {
      padding-left: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  &.disabledPointerEvents {
    pointer-events: none;
  }
`;

const ButtonIcon = styled('span', {
  shouldForwardProp: isPropValid,
})<{ hide?: boolean; spacing?: 1 | 0.5 }>`
  display: inherit;
  box-sizing: border-box;
  position: relative;
  width: ${({ hide }) => (hide ? 0 : 20)}px;
  height: 20px;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1);

  &.startIcon {
    margin-right: ${({ theme, spacing = 1 }) => theme.spacing(spacing)}px;
    margin-left: -${({ theme, spacing = 1 }) => theme.spacing(spacing)}px;
  }

  &.endIcon {
    margin-right: -${({ theme, spacing = 1 }) => theme.spacing(spacing)}px;
    margin-left: ${({ theme, spacing = 1 }) => theme.spacing(spacing)}px;
  }

  svg {
    font-size: 20px;
  }
`;

const ButtonLoadingIcon = styled('span', {
  shouldForwardProp: isPropValid,
})<{ darkBackground: boolean; isLoading: boolean }>`
  position: absolute;
  width: ${({ isLoading }) => (isLoading ? 100 : 0)}%;
  height: 100%;
  overflow: hidden;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1);

  circle {
    stroke: ${({ theme, darkBackground }) =>
      darkBackground ? 'white' : theme.palette.button.disabled}!important;
  }
`;

const LoadedIcon = styled('span', {
  shouldForwardProp: isPropValid,
})<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 250ms cubic-bezier(0.2, 0, 0, 1);
`;

const UserIcon = styled('span', {
  shouldForwardProp: isPropValid,
})<{ hide?: boolean }>`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 250ms cubic-bezier(0.2, 0, 0, 1);
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      type = 'button',
      variant = 'contained',
      color = 'primary',
      textColor = 'default',
      size = 'large',
      startIcon,
      endIcon,
      loading = false,
      disabled = false,
      disabledPointerEvents = false,
      testId,
      onClick,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
    }: ButtonProps,
    ref
  ) => {
    const [finishingLoad, setFinishingLoad] = useState<boolean>(false);

    useEffect(() => {
      // when loading state changes from false to true
      // set up a cleaning effect so that loading animation stops when loading state changes from true to false
      if (loading) {
        return () => {
          setFinishingLoad(true);
          setTimeout(() => {
            setFinishingLoad(false);
          }, 1000);
        };
      }
      return () => {};
    }, [loading]);

    const isLoading = loading || finishingLoad;

    const loadingIconComp = (
      <ButtonLoadingIcon
        className="startIcon"
        isLoading={isLoading}
        darkBackground={variant === 'contained'}
      >
        {loading && !finishingLoad && <LoadingLogo size="tiny" />}
        <LoadedIcon visible={finishingLoad}>
          <CheckIcon />
        </LoadedIcon>
      </ButtonLoadingIcon>
    );

    const startIconComp = (
      <ButtonIcon
        className="startIcon"
        hide={!startIcon && !isLoading}
        spacing={variant === 'text' ? 0.5 : 1}
      >
        <UserIcon hide={isLoading}>{startIcon}</UserIcon>
        {loadingIconComp}
      </ButtonIcon>
    );

    const endIconComp = endIcon && (
      <ButtonIcon className="endIcon" spacing={variant === 'text' ? 0.5 : 1}>
        {endIcon}
      </ButtonIcon>
    );

    const classes = getClasses({
      disabled: loading || disabled,
      disabledPointerEvents,
      loading,
    });

    return (
      <ButtonElement
        type={type}
        className={`${variant} ${size} ${classes}`}
        color={color}
        textColor={textColor}
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
          }
          if (!finishingLoad && onClick) {
            onClick(e);
          }
        }}
        data-cy={testId}
        data-testid={testId}
        ref={ref}
        onDoubleClick={() => undefined}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {startIconComp}
        {label}
        {endIconComp}
      </ButtonElement>
    );
  }
);

export default Button;

import { getFinancialYearEnd } from '@agoy/dates';
import { numberValue, stringValue } from '@agoy/document';
import { TaxSummaryPart } from '@agoy/tax-declaration-document';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';

const useTaxFreeAllowance = () => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [lastFetchedIncome, setLastFetchedIncome] = useState<number | null>(
    null
  );
  const [lastFetchedBirthyear, setLastFetchedBirthyear] = useState<
    number | null
  >(null);
  const [taxFreeAllowance, setTaxFreeAllowance] = useState<number | null>(null);

  const { currentFinancialYear } = useSelector((state) => state.customerView);
  const taxSummaryDocument = useSelector(
    (state) =>
      state.taxDeclarationView.formData[
        state.taxDeclarationView.viewFormId ?? ''
      ]?.document.TS as TaxSummaryPart
  );

  useEffect(() => {
    const hasUpdates = (birthYear: number, income: number) => {
      return lastFetchedBirthyear !== birthYear || lastFetchedIncome !== income;
    };

    const fetchTaxFreeAllowance = async () => {
      try {
        if (!taxSummaryDocument) {
          return;
        }

        const personalNumber = stringValue(
          taxSummaryDocument.fields.personalNumber
        );
        const income = numberValue(
          taxSummaryDocument.fields.SammanstallningForvarvsinkomst.table.rows
            .find((r) => r.id === 'A')
            ?.rows?.find((r) => r.id === 'earnedIncome')?.cells?.value
        );

        if (!income || !personalNumber || personalNumber.length < 4) {
          return;
        }

        const birthYear = parseInt(personalNumber.slice(0, 4), 10);
        const taxYear = parseInt(
          getFinancialYearEnd(currentFinancialYear ?? ''),
          10
        );

        if (!hasUpdates(birthYear, income)) {
          return;
        }

        const response = await asResultClass(
          sdk.getTaxFreeAllowance({
            birthYear,
            taxYear,
            income,
          })
        );
        if (response.ok) {
          setLastFetchedIncome(income);
          setLastFetchedBirthyear(birthYear);
          setTaxFreeAllowance(response.val.taxFreeAllowance);
        }
      } catch (err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    };

    fetchTaxFreeAllowance();
  }, [
    sdk,
    dispatch,
    currentFinancialYear,
    taxSummaryDocument,
    lastFetchedIncome,
    lastFetchedBirthyear,
  ]);

  return { taxFreeAllowance };
};

export default useTaxFreeAllowance;

import { AgoyTableRow } from '@agoy/document';

export const propertiesForSorting = [
  'Ägande lägenheter',
  'Hyreshus',
  'Industri',
  'Elproduktionsenheter',
  'Lantbruk',
  'BRF',
];

export const propertyTypeSelects = [
  {
    value: 'Ägande lägenheter',
    options: [
      {
        group: 'Ägande lägenheter',
        value: 'Obebyggd tomtmark för ägarlägeneheter',
      },
      {
        group: 'Ägande lägenheter',
        value:
          'Ägarlägenheter som är taxerade under uppförande och tillhörande tomtmark.',
      },
      {
        group: 'Ägande lägenheter',
        value:
          'Tomtmark som är bebyggd med ägarlägenheter som har annan ägare (arrende tomt)',
      },
      {
        group: 'Ägande lägenheter',
        value:
          'Tomtmark som är bebyggd med enbart ägarlägenheter som saknar byggnadsvärde, dvs under 50 tkr',
      },
    ],
  },
  {
    value: 'Hyreshus',
    options: [
      {
        group: 'Hyreshus',
        value: 'Lokaler under uppförande och tillhörande tomtmark',
      },
      {
        group: 'Hyreshus',
        value: 'Lokaler och tillhörande mark',
      },
      {
        group: 'Hyreshus',
        value: 'Obebyggd tomtmark',
      },
      {
        group: 'Hyreshus',
        value: 'Tomtmark som har annan ägare( arende)',
      },
      {
        group: 'Hyreshus',
        value:
          'Tomtmark som är bebyggd som saknar byggnadsvärde pga byggnadsvärde understiger 50 tkr',
      },
    ],
  },
  {
    value: 'Industri',
    options: [
      {
        group: 'Industri',
        value: 'Industrienheter',
      },
      {
        group: 'Industri',
        value: 'Industrienheter med täktmark',
      },
    ],
  },
  {
    value: 'Elproduktionsenheter',
    options: [
      {
        group: 'Elproduktionsenheter',
        value: 'Vattenkraft',
      },
      {
        group: 'Elproduktionsenheter',
        value: 'Värmekraftverk',
      },
      {
        group: 'Elproduktionsenheter',
        value: 'Vindkraftsverk',
      },
      {
        group: 'Elproduktionsenheter',
        value: 'Tomtmark till vindkraftsverk',
      },
    ],
  },
  {
    value: 'Lantbruk',
    options: [
      {
        group: 'Lantbruk',
        value: 'Småhus under uppförande',
      },
      {
        group: 'Lantbruk',
        value: 'Småhus',
      },
      {
        group: 'Lantbruk',
        value: 'Småhus på ofrigrund',
      },
      {
        group: 'Lantbruk',
        value: 'Tomtmark där annan äger huset',
      },
      {
        group: 'Lantbruk',
        value: 'Obebyggd småhus tomt',
      },
    ],
  },
  {
    value: 'BRF',
    options: [
      {
        group: 'BRF',
        value: 'Fastighetsskatt på lokaler',
      },
      {
        group: 'BRF',
        value: 'Lägenheter',
      },
    ],
  },
];

/**
 * TODO: these probably should be stored somewhere as configs
 */
const SMALL_HOUSE_AMOUNT = {
  '2021': 8524,
  '2022': 8874,
  '2023': 9287,
  '2024': 9525,
  '2025': 10074,
};

const SMALL_HOUSE_FREEHOLD_PLOT_AMOUNT = {
  '2021': 4262,
  '2022': 4437,
  '2023': 4643,
  '2024': 4762,
  '2025': 5037,
};

const PLOT_OWNED_BY_SOMEONE_ELSE_AMOUNT = {
  '2021': 4262,
  '2022': 4437,
  '2023': 4643,
  '2024': 4762,
  '2025': 5037,
};

const RESIDENTIAL_APARTMENT_AMOUNT = {
  '2022': 1519,
  '2023': 1589,
  '2024': 1630,
  '2025': 1724,
};

export const getPropertyTypeConfig = (financialYear: string) => {
  return {
    'Obebyggd tomtmark för ägarlägeneheter': {
      tax: 0.01,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Ägarlägenheter som är taxerade under uppförande och tillhörande tomtmark.':
      {
        tax: 0.01,
        fee: false,
        amount: '',
        fixedAmount: '',
      },
    'Tomtmark som är bebyggd med ägarlägenheter som har annan ägare (arrende tomt)':
      {
        tax: 0.01,
        fee: false,
        amount: '',
        fixedAmount: '',
      },
    'Tomtmark som är bebyggd med enbart ägarlägenheter som saknar byggnadsvärde, dvs under 50 tkr':
      {
        tax: 0.01,
        fee: false,
        amount: '',
        fixedAmount: '',
      },
    'Lokaler under uppförande och tillhörande tomtmark': {
      tax: 0.01,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Lokaler och tillhörande mark': {
      tax: 0.01,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Obebyggd tomtmark': {
      tax: 0.004,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Tomtmark som har annan ägare( arende)': {
      tax: 0.004,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Tomtmark som är bebyggd som saknar byggnadsvärde pga byggnadsvärde understiger 50 tkr':
      {
        tax: 0.004,
        fee: false,
        amount: '',
        fixedAmount: '',
      },
    Industrienheter: {
      tax: 0.005,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Industrienheter med täktmark': {
      tax: 0.005,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    Vattenkraft: {
      tax: 0.005,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    Värmekraftverk: {
      tax: 0.005,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    Vindkraftsverk: {
      tax: 0.002,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Tomtmark till vindkraftsverk': {
      tax: 0.005,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Småhus under uppförande': {
      tax: 0.01,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    Småhus: {
      tax: 0.0075,
      fee: false,
      amount: SMALL_HOUSE_AMOUNT[financialYear] || 8524,
      fixedAmount: '',
    },
    'Småhus på ofrigrund': {
      tax: 0.0075,
      fee: false,
      amount: SMALL_HOUSE_FREEHOLD_PLOT_AMOUNT[financialYear] || 4262,
      fixedAmount: '',
    },
    'Tomtmark där annan äger huset': {
      tax: 0.0075,
      fee: false,
      amount: PLOT_OWNED_BY_SOMEONE_ELSE_AMOUNT[financialYear] || 4262,
      fixedAmount: '',
    },
    'Obebyggd småhus tomt': {
      tax: 0.01,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    'Fastighetsskatt på lokaler': {
      tax: 0.01,
      fee: false,
      amount: '',
      fixedAmount: '',
    },
    Lägenheter: {
      tax: 0.003,
      fee: true,
      amount: '',
      fixedAmount: RESIDENTIAL_APARTMENT_AMOUNT[financialYear] || 1519,
    },
  };
};

export const validateRow = (row: AgoyTableRow): string => {
  const { cells = {} } = row;
  let error = '';

  const cellsEntries = Object.entries(cells);

  if (cellsEntries.every(([, value]) => 'value' in value && !value.value)) {
    return error;
  }

  cellsEntries.forEach(([key, value]) => {
    const valueEntries = Object.entries(value);

    if (key === 'owned') {
      valueEntries.forEach(([valuesKey, valuesValue]) => {
        if (valuesKey === 'value' && (!valuesValue || valuesValue > 1)) {
          error = 'propertyTax.validation.percentage.error';
        }
      });
    } else if (
      key === 'name' ||
      key === 'type' ||
      key === 'taxValue' ||
      key === 'numberOfLGH'
    ) {
      valueEntries.forEach(([valuesKey, valuesValue]) => {
        if (valuesKey === 'value' && !valuesValue) {
          error = 'propertyTax.validation.error';
        }
      });
    }
  });

  return error;
};

export const getValidatedRows = (
  rows: AgoyTableRow[]
): { rows: AgoyTableRow[]; error: string } => {
  let error = '';

  const preparedRows = rows.filter((row: AgoyTableRow) => {
    const rowError = validateRow(row);

    if (rowError) {
      error = rowError;
    }

    return !rowError && row;
  });

  return { rows: preparedRows, error };
};

export const getTableDefaultValues = (columnKeys: string[]) => {
  const defaultColumns = columnKeys.map((key) => ({ id: key, align: 'left' }));

  const defaultCells = {};

  columnKeys.forEach((key) => {
    if (key === 'type') {
      defaultCells[key] = {
        type: 'string',
        value: '',
        align: 'left',
        format: {
          type: 'select',
          options: propertyTypeSelects,
        },
      };
    } else if (key === 'owned' || key === 'taxPercentage') {
      defaultCells[key] = {
        type: 'ref',
        value: undefined,
        align: 'left',
      };
    } else if (key === 'name') {
      defaultCells[key] = {
        type: 'string',
        value: '',
        align: 'left',
      };
    } else if (key === 'taxExempt') {
      defaultCells[key] = {
        type: 'boolean',
        value: '',
        align: 'left',
      };
    } else {
      defaultCells[key] = {
        type: 'number',
        value: undefined,
        align: 'left',
      };
    }
  });

  const defaultRows = [
    {
      active: true,
      id: '1',
      cells: defaultCells,
    },
    {
      active: true,
      id: '2',
      cells: defaultCells,
    },
    {
      active: true,
      id: '3',
      cells: defaultCells,
    },
  ];

  return {
    defaultColumns,
    defaultCells,
    defaultRows,
  };
};

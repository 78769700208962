import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { TaxSummaryFields } from '@agoy/tax-declaration-document';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormField } from 'redux/actions';
import { Municipality } from '@agoy/api-sdk-core';
import { ccyFormat } from '@agoy/common';
import { booleanValue, numberValue, stringValue } from '@agoy/document';

import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';

import { Paper } from '../Common/Components';
import { Input } from '../Common/Input';
import useMunicipalities from '../Common/hooks/fetchMunicipalities';
import DropdownWithSelect from '../Common/DropdownWithSelect';
import TaxSummaryTable from './TaxSummaryTable';
import useTaxFreeAllowance from '../Common/hooks/fetchTaxFreeAllowance';
import Header from '../Common/Header';

const InputGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
`;

const TopWrapper = styled.div`
  display: grid;
  border: 1px solid #000;
  border-right: none;
  border-bottom: none;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  > div {
    padding: 4px;
    border: 1px solid #000;
    border-top: none;
    border-left: none;
  }
`;

const TaxSum = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-column-gap: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

const TaxSumHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing(4)}px;
`;

const TaxSumValue = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  background: rgba(0, 0, 0, 0.05);
`;

type TaxSummaryProps = {
  fields: TaxSummaryFields;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  orgName: string | undefined;
};

const TaxSummary = ({ fields, postalCode, city, orgName }: TaxSummaryProps) => {
  const { municipalities } = useMunicipalities();
  const { taxFreeAllowance } = useTaxFreeAllowance();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (taxFreeAllowance === null) {
      return;
    }

    dispatch(
      updateTaxDeclarationFormField('TS', 'taxFreeAllowance', taxFreeAllowance)
    );
  }, [taxFreeAllowance, dispatch]);

  const tables = [
    {
      tableId: 'fields.SammanstallningForvarvsinkomst.table',
      table: fields.SammanstallningForvarvsinkomst.table,
    },
    {
      tableId: 'fields.SammanstallningKapitalinkomst.table',
      table: fields.SammanstallningKapitalinkomst.table,
    },
    {
      tableId: 'fields.SkatterOchAvgifter.table',
      table: fields.SkatterOchAvgifter.table,
    },
    {
      tableId: 'fields.EnskildNaringsverksamhet.table',
      table: fields.EnskildNaringsverksamhet.table,
    },
    {
      tableId: 'fields.EgenavgifterOchLoneavgift.table',
      table: fields.EgenavgifterOchLoneavgift.table,
    },
    {
      tableId: 'fields.Skattereduktioner.table',
      table: fields.Skattereduktioner.table,
    },
    {
      tableId: 'fields.SammanstalldSkatt.table',
      table: fields.SammanstalldSkatt.table,
    },
    {
      tableId: 'fields.SammanstallningBerakningar.table',
      table: fields.SammanstallningBerakningar.table,
    },
  ];

  const activeSummary = useMemo(() => {
    const taxSummaryTableGroup =
      fields.SammanstallningBerakningar.table.rows?.[0].rows;
    return taxSummaryTableGroup?.find(
      (row) => row.type === 'sum' && booleanValue(row.active)
    );
  }, [fields]);

  const summaryType = activeSummary?.id ?? 'taxToPay';

  return (
    <Paper gapSize={3}>
      <Header
        title="Sammanställning skatt"
        subTitle="Privatperson"
        postalCode={postalCode}
        city={city}
        orgName={orgName}
      >
        <InputGroup>
          <Input
            field={fields.date}
            label="Datum"
            keyValue="date"
            partType="taxSummary"
            type="date"
          />
          <Input
            field={fields.financialYear}
            label="Inkomstår"
            keyValue="financialYear"
            partType="taxSummary"
          />
        </InputGroup>
      </Header>
      <TopWrapper>
        <Inner>
          <Input
            field={fields.name}
            label="Namn"
            keyValue="name"
            partType="taxSummary"
          />
          <Input
            field={fields.personalNumber}
            label="Person-/Organisationsnummer"
            keyValue="personalNumber"
            partType="taxSummary"
          />
        </Inner>
        <Inner>
          <DropdownWithSelect<Municipality>
            label="Hemmortskommun"
            placeholder="Välj kommun"
            items={municipalities}
            value={stringValue(fields.municipality)}
            onValueChange={(item) => {
              dispatch(
                updateTaxDeclarationFormField('TS', 'municipality', item.name)
              );

              dispatch(
                updateTaxDeclarationFormField(
                  'TS',
                  'burialFeeRate',
                  item.funeralTax
                )
              );

              dispatch(
                updateTaxDeclarationFormField(
                  'TS',
                  'municipalTaxRate',
                  item.tax
                )
              );
            }}
          />

          <Input
            field={fields.municipalTaxRate}
            keyValue="municipalTaxRate"
            type="percentage"
            label="Kommunal skattesats %"
            partType="taxSummary"
          />
          <Input
            field={fields.burialFeeRate}
            keyValue="burialFeeRate"
            label="Begravningsavgift %"
            partType="taxSummary"
            type="percentage"
            displayDecimals={3}
          />
          <Input
            field={fields.religiousCommunitiesFee}
            keyValue="religiousCommunitiesFee"
            label="Avgift trosamfund %"
            partType="taxSummary"
            type="percentage"
          />
        </Inner>
      </TopWrapper>

      <TaxSum>
        <div>
          <TaxSumHeader>
            <Typography variant="h4" margin="none">
              {formatMessage({ id: `tax.summary.${summaryType}.title` })}
            </Typography>
          </TaxSumHeader>
          <Typography margin="none">
            {formatMessage({ id: `tax.summary.${summaryType}.text` })}
          </Typography>
        </div>
        <TaxSumValue>
          <Typography margin="none">
            {stringValue(activeSummary?.cells?.label)}
          </Typography>
          <Typography variant="caption" margin="none" as="div">
            {formatMessage({ id: 'tax.summary.excludingInterest' })}
          </Typography>
          <Typography variant="h3" margin="none" as="p">
            {ccyFormat(numberValue(activeSummary?.cells?.value))}
          </Typography>
        </TaxSumValue>
      </TaxSum>

      {tables.map((table) => (
        <React.Fragment key={table.tableId}>
          <TaxSummaryTable tableId={table.tableId} table={table.table} />
        </React.Fragment>
      ))}
    </Paper>
  );
};

export default TaxSummary;

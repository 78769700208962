import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  AgoyTableRow,
  AgoyTableColumn,
  Cell as CellType,
} from '@agoy/document';
import { NumberFormatType } from '@agoy/common';
import ServiceContext, {
  TableServiceType,
} from './Context/TableServiceContext';
import CustomTableRow from './TableRow';
import Button from '../Buttons/Button';

type UpdateRowsPayload = Parameters<TableServiceType['updateRows']>[0][0];

export type SubRowType = {
  row: AgoyTableRow;
  baseId: string;
};

interface TableBodyProps {
  baseId: string;
  tableId: string;
  rows: AgoyTableRow[];
  columns: AgoyTableColumn[];
  editing?: boolean;
  print?: boolean;
  canDrag?: boolean;
  hoverColumnId?: string;
  isLocked?: boolean;
  addNewRowId?: string;
  addNewRowLabelId?: string;
  addNewColumnPosition?: number;
  rowsWithoutDrag?: string[];
  canToggleActive?: boolean;
  canAddRows?: boolean;
  canDeleteRows?: boolean;
  nonEditableRowColumns?: string[];
  selectedRowId?: string;
  withPlaceholder?: boolean;
  accountRanges?: any;
  isRowSelectable?: (rowId: string) => boolean;
  onRowSelect?: (rowId: string) => void;
  numberFormat?: (rowId: string) => NumberFormatType;
  numberFormatColumn?: (col: string) => NumberFormatType;
  renderRow?: (subRow: SubRowType, index?: number) => React.ReactElement | null;
  renderCell?: (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
    isDragging: boolean;
  }) => React.ReactElement | null;
  renderFooterRow?: () => React.ReactElement;

  onDeleteRow?: (row: AgoyTableRow, id: string) => void;
  onToggleRow?: (row: AgoyTableRow, id: string) => void;
  onAddRow?: (baseId: string) => void;
  onColumnBlur?: (colId: string, newValue: string) => void;
  handleShowRowInfo?: (
    cells: Record<string, CellType> | undefined
  ) => string | undefined;
  onValueChange?: (key: string, newValue: string) => void;
  additionalButtons?: { label: string; onClick: () => void }[];
}

const TableBodyComponent = ({
  baseId,
  tableId,
  rows,
  columns,
  editing = false,
  print = false,
  canDrag = false,
  isLocked = false,
  hoverColumnId,
  addNewRowId = '',
  addNewRowLabelId = 'add.row',
  addNewColumnPosition,
  rowsWithoutDrag,
  canToggleActive,
  canAddRows,
  canDeleteRows,
  nonEditableRowColumns,
  selectedRowId,
  accountRanges,
  isRowSelectable,
  onRowSelect,
  numberFormat,
  numberFormatColumn,
  renderRow,
  renderCell,
  withPlaceholder,
  onAddRow,
  onDeleteRow,
  onToggleRow,
  onColumnBlur,
  renderFooterRow,
  handleShowRowInfo,
  onValueChange,
  additionalButtons,
}: TableBodyProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(ServiceContext);

  const frameId = useRef<number | null>(null);

  const [subRows, setSubRows] = useState<SubRowType[]>([]);
  const [currentAddNewRowId, setAddNewRowId] = useState('');

  const getSubRows = useCallback(
    (row: AgoyTableRow, id: string) => {
      let result: { row: AgoyTableRow; baseId: string }[] = [];

      if (row.rows) {
        row.rows.forEach((item) => {
          const newBaseId = `${id}.${row.id}`;
          const resultRows = getSubRows(item, newBaseId);
          if (addNewRowId && newBaseId.includes(addNewRowId)) {
            setAddNewRowId(resultRows[resultRows.length - 1].row.id);
          }
          result = [...result, ...resultRows];
        });
      }

      if (row.cells) {
        if (addNewRowId && row.id.includes(addNewRowId)) {
          setAddNewRowId(row.id);
        }
        return [{ baseId: id, row }];
      }

      return result;
    },
    [addNewRowId]
  );

  const sortSubRows = (currentSubRows: SubRowType[]) => {
    const newSubRows: SubRowType[] = [];
    const newSubRowsWithSortKey: SubRowType[] = [];
    currentSubRows.forEach((item) => {
      if (typeof item.row.sortKey === 'number') {
        newSubRowsWithSortKey.push(item);
      } else {
        newSubRows.push(item);
      }
    });

    newSubRowsWithSortKey.sort(
      (a: SubRowType, b: SubRowType) =>
        (a.row?.sortKey || 0) - (b.row?.sortKey || 0)
    );
    newSubRowsWithSortKey.forEach((item) => {
      newSubRows.splice(item.row.sortKey || 0, 0, item);
    });
    return newSubRows;
  };

  useEffect(() => {
    let result: SubRowType[] = [];

    rows.forEach((item) => {
      result = [...result, ...getSubRows(item, tableId)];
    });

    setSubRows(sortSubRows(result));
  }, [rows, tableId, getSubRows]);

  const drawFrame = (newSubRows) => {
    if (!frameId.current) {
      const updateFunction = () => {
        setSubRows(newSubRows);
        frameId.current = null;
      };

      frameId.current = requestAnimationFrame(updateFunction);
    }
  };

  const handleDragEnd = useCallback(
    (draggedRow: SubRowType) => {
      const rowIndex = subRows.findIndex(
        (item) => item.row.id === draggedRow.row.id
      );
      const newSubRows = [...subRows];

      if (rowIndex !== -1) {
        newSubRows[rowIndex].row.sortKey = rowIndex;
      }

      const rowsWithSortKey: UpdateRowsPayload[] = [];

      newSubRows.forEach((item, index) => {
        if (typeof item.row.sortKey === 'number') {
          rowsWithSortKey.push({
            id: `${item.baseId}.${item.row.id}`,
            sortKey: index,
          });
        }
      });

      service?.updateRows(rowsWithSortKey);
    },
    [service, subRows]
  );

  const handleDragHover = useCallback(
    (draggedRow: SubRowType, hoverRow: SubRowType) => {
      const draggedIndex = subRows.findIndex(
        (item) => item.row.id === draggedRow.row.id
      );
      const hoverIndex = subRows.findIndex(
        (item) => item.row.id === hoverRow.row.id
      );

      const newSubRows = [...subRows];
      newSubRows.splice(draggedIndex, 1);
      newSubRows.splice(hoverIndex, 0, draggedRow);

      drawFrame(newSubRows);
    },
    [subRows]
  );

  const handleAddRow = (baseId: string) => {
    if (onAddRow) {
      onAddRow(baseId);
    } else {
      service?.addRow(baseId);
    }
  };

  const skipColumns = useMemo(
    () =>
      addNewColumnPosition && !print && editing
        ? [addNewColumnPosition + 1]
        : [],
    [addNewColumnPosition, print, editing]
  );
  return (
    <TableBody>
      {subRows.map((item, index) => (
        <React.Fragment key={`${item.baseId}.${item.row.id}`}>
          {renderRow ? (
            renderRow(item, index)
          ) : (
            <CustomTableRow
              tableId={item.baseId}
              baseId={baseId}
              row={item.row}
              columns={columns}
              skipColumns={skipColumns}
              editing={editing}
              print={print}
              hoverColumnId={hoverColumnId}
              canToggleActive={canToggleActive}
              isLocked={isLocked}
              canDrag={canDrag}
              disableDrag={rowsWithoutDrag?.includes(item.row.id)}
              onDragEnd={handleDragEnd}
              onDragHover={handleDragHover}
              onDeleteRow={onDeleteRow}
              onColumnBlur={onColumnBlur}
              onToggleRow={onToggleRow}
              numberFormat={
                numberFormat ? numberFormat(item.row.id) : undefined
              }
              numberFormatColumn={numberFormatColumn}
              renderCell={renderCell}
              canAddRows={canAddRows}
              canDeleteRows={canDeleteRows}
              nonEditableRowColumns={nonEditableRowColumns}
              selected={item.row.id === selectedRowId}
              selectable={isRowSelectable && isRowSelectable(item.row.id)}
              onSelect={onRowSelect}
              withPlaceholder={withPlaceholder}
              handleShowRowInfo={handleShowRowInfo}
              accountRanges={accountRanges}
              onValueChange={onValueChange}
            />
          )}

          {editing &&
            canAddRows &&
            (currentAddNewRowId === item.row.id ||
              (!currentAddNewRowId && index === subRows.length - 1)) && (
              <TableRow>
                <TableCell colSpan={columns.length + skipColumns.length + 3}>
                  <Button
                    label={formatMessage({ id: addNewRowLabelId })}
                    startIcon={<AddCircleOutlineIcon />}
                    disabled={isLocked}
                    onClick={() => handleAddRow(item.baseId)}
                    variant="text"
                    size="medium"
                  />
                  {additionalButtons?.map((button) => {
                    return (
                      <Button
                        label={button.label}
                        key={button.label}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => button.onClick()}
                        variant="text"
                        size="medium"
                      />
                    );
                  })}
                </TableCell>
              </TableRow>
            )}
        </React.Fragment>
      ))}
      {editing && canAddRows && !subRows.length && (
        <TableRow>
          <TableCell colSpan={columns.length + skipColumns.length + 3}>
            <Button
              label={formatMessage({ id: addNewRowLabelId })}
              disabled={isLocked}
              onClick={() => handleAddRow(tableId)}
              variant="text"
              size="medium"
            />
          </TableCell>
        </TableRow>
      )}
      {renderFooterRow && (
        <TableRow>
          <TableCell colSpan={columns.length + skipColumns.length + 3}>
            {renderFooterRow()}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableBodyComponent;

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { useIntl } from 'react-intl';

import {
  SpecificationCellType,
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationType,
} from '_clients/types/types';
import { useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import LoadingLogo from '_shared/components/LoadingLogo';
import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import Drawer from '_shared/components/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, Popover } from '@material-ui/core';
import { PrintOutlined, CachedOutlined } from '@material-ui/icons';

import ColumnSelector from './ColumnSelector/ColumnSelector';
import PeriodDataContext from '../PeriodDataContext';
import DraggableTable from './DraggableTable';

interface SpecificationTableProps {
  accountNumber: string;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
`;

const TableWrapper = styled.div`
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid #ccc;
  max-width: fit-content;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  height: 20px;
  padding: 2px;
`;

const RotatingIcon = styled(ExpandMoreIcon)`
  transition: transform 0.3s;

  &.open {
    transform: rotate(180deg);
  }

  &.closed {
    transform: rotate('0deg');
  }
`;

const MirroredCachedOutlined = styled(CachedOutlined)`
  transform: scaleX(-1);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: ${(props) => props.theme.spacing(1)}px;

    .MuiMenuItem-root {
      text-wrap: wrap;
      font-size: 14px;
    }
  }
`;

const PopoverWrapper = styled.div`
  max-width: 216px;
  padding: 8px 0;
`;

const SpecificationTable = ({ accountNumber }: SpecificationTableProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [columns, setColumns] = useState<SpecificationColumnType[]>([]);
  const [rows, setRows] = useState<SpecificationRowType[]>([]);
  const [specification, setSpecification] = useState<SpecificationType>({});
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElOtherTasksButton, setAnchorElOtherTasksButton] =
    useState<null | HTMLElement>(null);

  const { clientId, period } = useContext(PeriodDataContext);

  const toggleDrawer = () => {
    setDrawerOpen((currentValue) => !currentValue);
  };

  const initSpecification = useCallback(async () => {
    try {
      const response = await sdk.addSpecification({
        clientid: clientId,
        periodId: period.id,
        accountNumber: Number(accountNumber),
      });

      if (response) {
        setSpecification(response);
      }
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [accountNumber, clientId, dispatch, period.id, sdk]);

  useEffect(() => {
    const getSpecifications = async () => {
      try {
        const response = await sdk.getSpecifications({
          clientid: clientId,
          periodId: period.id,
          accountNumbers: [Number(accountNumber)],
        });

        const data = response.accounts[accountNumber];

        if (Object.keys(response.accounts).length === 0) {
          await initSpecification();
          getSpecifications();
        } else {
          setColumns(data.columns);
          setRows(data.rows);
          setSpecification(data.specification);
        }
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    };

    getSpecifications();
  }, [clientId, period, accountNumber, sdk, initSpecification, dispatch]);

  const addRow = useCallback(async () => {
    if (!specification.id) {
      return;
    }

    try {
      const newRow = await sdk.addSpecificationRow({
        clientid: clientId,
        specificationId: specification.id,
      });

      setRows((prevRows) => [...prevRows, { ...newRow, cells: [] }]);
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, sdk, specification.id]);

  const getUpdatedCells = (
    cells: SpecificationCellType[],
    updatedCell: {
      rowId: number;
      columnId: number;
      value: string;
    }
  ) => {
    const updatedCells = [...cells];
    const index = cells.findIndex((c) => c.columnId === updatedCell.columnId);

    if (index === -1) {
      updatedCells.push(updatedCell);
    } else {
      updatedCells[index] = updatedCell;
    }

    return updatedCells;
  };

  const updateCell = useCallback(
    async (value: string, row: SpecificationRowType, columnId: number) => {
      const specificationId = specification.id;

      if (!specificationId) {
        return;
      }

      const data = {
        clientid: clientId,
        specificationId,
        rowId: row.id,
        requestBody: {
          columnId,
          value,
        },
      };

      try {
        const cellExists = !!row.cells.find((c) => c.columnId === columnId);

        const cellData = cellExists
          ? await sdk.updateSpecificationCell(data)
          : await sdk.addSpecificationCell(data);

        setRows((prevRows) =>
          prevRows.map((prevRow) =>
            prevRow.id === row.id
              ? {
                  ...prevRow,
                  cells: getUpdatedCells(row.cells, cellData),
                }
              : prevRow
          )
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  const deleteRows = useCallback(
    async (rowsIds: number[]) => {
      if (!specification.id) {
        return;
      }
      try {
        await sdk.deleteRows({
          clientid: clientId,
          specificationId: specification.id,
          rowIds: rowsIds,
        });
        setSelectedRows((currentValue) =>
          currentValue.filter((id) => !rowsIds.includes(id))
        );
        setRows((currentValue) =>
          currentValue.filter((row) => !rowsIds.includes(row.id))
        );

        dispatch(
          addGlobalMessage(
            'info',
            rowsIds.length > 1
              ? 'hidden.specification.multipleRowsRemoved'
              : 'hidden.specification.rowRemoved'
          )
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  const moveRow = useCallback(
    async (currentIndex: number, moveToIndex: number) => {
      if (!specification.id) {
        return;
      }

      const updatedOrder = rows.reduce<{ id: number; order: number }[]>(
        (acc, row, index) => [
          ...acc,
          { id: row.id, order: row.order || index + 1 },
        ],
        []
      );

      updatedOrder[currentIndex].order = moveToIndex;
      updatedOrder[moveToIndex].order = currentIndex;

      try {
        await sdk.updateSpecificationRowsOrder({
          clientid: clientId,
          specificationId: specification.id,
          requestBody: updatedOrder,
        });
        setRows((currentValue) =>
          currentValue
            .map((row) => {
              const order = updatedOrder.find(
                (item) => item.id === row.id
              )?.order;

              return { ...row, order };
            })
            .sort((a, b) => (a.order || 0) - (b.order || 0))
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, rows, sdk, specification.id]
  );

  const handleToggleColumn = useCallback(
    async (column: SpecificationColumnType, value: boolean) => {
      if (!specification.id) {
        return;
      }

      try {
        if (!value) {
          await sdk.deleteSpecificationSelectedColumn({
            clientid: clientId,
            specificationId: specification.id,
            selectedColumnId: column.id,
          });

          setColumns((currentValue) =>
            currentValue.filter((c) => c.id !== column.id)
          );
        } else {
          await sdk.addSpecificationSelectedColumn({
            clientid: clientId,
            specificationId: specification.id,
            requestBody: { columnId: column.id },
          });

          setColumns((currentValue) => [...currentValue, column]);
        }
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  if (!specification.id) {
    return (
      <LoadingContainer>
        <LoadingLogo size="medium" />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        width="300px"
        headerTitle={formatMessage({ id: 'hidden.specification.editTable' })}
      >
        <ColumnSelector
          selectedColumns={columns}
          rows={rows}
          onToggleColumn={handleToggleColumn}
          onClose={toggleDrawer}
        />
      </Drawer>

      <ActionButtons>
        {selectedRows.length ? (
          <>
            {selectedRows.length === rows.length ? (
              <Typography margin="none">
                {formatMessage({ id: 'table.draggable.allSelected' })}
              </Typography>
            ) : (
              <Typography margin="none">
                {selectedRows.length}{' '}
                {formatMessage({ id: 'table.draggable.selected' })}
              </Typography>
            )}

            <Button
              size="small"
              variant="outlined"
              color="danger"
              label={formatMessage({ id: 'table.draggable.deleteRows' })}
              startIcon={<StyledDeleteIcon />}
              onClick={() => deleteRows(selectedRows)}
            />
          </>
        ) : (
          <>
            <Button
              size="small"
              variant="outlined"
              label={formatMessage({
                id: 'hidden.specification.button.otherTasks',
              })}
              onClick={(event) =>
                setAnchorElOtherTasksButton(event.currentTarget)
              }
              endIcon={
                <RotatingIcon
                  className={anchorElOtherTasksButton ? 'open' : 'closed'}
                />
              }
            />
            <StyledPopover
              open={!!anchorElOtherTasksButton}
              anchorEl={anchorElOtherTasksButton}
              onClose={() => setAnchorElOtherTasksButton(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <PopoverWrapper>
                <MenuItem onClick={() => setAnchorElOtherTasksButton(null)}>
                  <IconWrapper>
                    <PrintOutlined />
                  </IconWrapper>
                  {formatMessage({
                    id: 'hidden.specification.button.otherTasks.print',
                  })}
                </MenuItem>
                <MenuItem onClick={() => setAnchorElOtherTasksButton(null)}>
                  <IconWrapper>
                    <MirroredCachedOutlined />
                  </IconWrapper>
                  {formatMessage({
                    id: 'hidden.specification.button.otherTasks.resync',
                  })}
                </MenuItem>
              </PopoverWrapper>
            </StyledPopover>
          </>
        )}
      </ActionButtons>

      <TableWrapper>
        <DraggableTable
          columns={columns}
          rows={rows}
          selectedRows={selectedRows}
          onUpdateCell={updateCell}
          onMoveRow={moveRow}
          onMoveColumn={(x) => console.log(x)}
          onNewRow={addRow}
          onSelectRow={setSelectedRows}
          onOpenDrawer={toggleDrawer}
          onDeleteRows={deleteRows}
        />
      </TableWrapper>
    </Container>
  );
};

export default SpecificationTable;

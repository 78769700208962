import React, { ChangeEvent, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Add from '@material-ui/icons/Add';

import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import { Input } from '_shared/components/Inputs/v2/Input';

import { debounce } from 'lodash';
import DraggableCell from './DraggableCell';
import DraggableHeader from './DraggableHeader';
import RowOptions from './RowOptions';

const StyledTable = styled.table`
  // we can't use border: collapse; because of sticky columns :(
  // so have to add borders manually
  border-spacing: 0;
  border-radius: 10px;
  table-layout: fixed;
  width: max-content;

  th,
  td {
    text-align: start;
    border-right: 1px solid #ccc;
    padding: 8px;
    height: 40px;
  }

  td {
    border-top: 1px solid #ccc;
  }

  tr {
    th:first-of-type,
    td:first-of-type {
      width: 28px;
      position: sticky;
      left: 0;
      background-color: white;
    }

    th:nth-of-type(2),
    td:nth-of-type(2) {
      position: sticky;
      left: 28px;
      background-color: white;
    }

    th:nth-last-of-type(2),
    td:nth-last-of-type(2) {
      border-right: none;
    }

    td:last-of-type,
    th:last-of-type {
      position: sticky;
      right: 0;
      border-left: 1px solid #ccc;
      border-right: none;
      background-color: white;
    }
  }
`;

const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  background: #fff;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AddIcon = styled(Add)`
  width: 20px;
  height: 20px;
`;

type DraggableTableProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  selectedRows: number[];
  onUpdateCell: (
    value: string,
    row: SpecificationRowType,
    columnId: number
  ) => void;
  onNewRow: () => void;
  onSelectRow: (rowIds: number[]) => void;
  onMoveRow: (fromIndex: number, toIndex: number) => void;
  onMoveColumn: (updatedColumns: string[]) => void;
  onDeleteRows: (rowId: number[]) => void;
  onOpenDrawer: () => void;
};

const DraggableTable = ({
  columns,
  rows,
  selectedRows,
  onUpdateCell,
  onNewRow,
  onSelectRow,
  onMoveRow,
  onMoveColumn,
  onDeleteRows,
  onOpenDrawer,
}: DraggableTableProps) => {
  const { formatMessage } = useIntl();

  const tableRef = useRef<HTMLTableElement>(null);

  const setFocus = () => {
    if (tableRef.current) {
      const selectedRow =
        tableRef.current.rows[tableRef.current.rows.length - 3];
      if (selectedRow) {
        const selectedCell = selectedRow.cells[1];
        if (selectedCell) {
          (selectedCell.firstElementChild as HTMLTableCellElement).focus();
        }
      }
    }
  };

  useEffect(() => {
    setFocus();
  }, [rows.length]);

  const isAllRowsSelected =
    rows.length > 0 && selectedRows.length === rows.length;

  const moveRowUp = (rowId: number) => {
    const fromIndex = rows.findIndex((row) => row.id === rowId);
    onMoveRow(fromIndex, fromIndex - 1);
  };

  const moveRowDown = (rowId: number) => {
    const fromIndex = rows.findIndex((row) => row.id === rowId);
    onMoveRow(fromIndex, fromIndex + 1);
  };

  const moveColumn = (fromColumnIndex: number, toColumnIndex: number) => {
    const updatedColumns = [...columns];
    const [movedColumn] = updatedColumns.splice(fromColumnIndex, 1);
    updatedColumns.splice(toColumnIndex, 0, movedColumn);
    onMoveColumn(['Column Change']); // Notify parent of column change
  };

  const handleCellUpdate = debounce(
    (
      e: ChangeEvent<HTMLInputElement>,
      row: SpecificationRowType,
      cellId: number
    ) => {
      e.preventDefault();
      onUpdateCell(e.target.value, row, cellId);
    },
    600
  );

  const handleSelectAll = () => {
    if (isAllRowsSelected) {
      onSelectRow([]);
    } else {
      onSelectRow(rows.map((row) => row.id));
    }
  };

  const handleSelectRow = (id: number) => {
    if (selectedRows.includes(id)) {
      onSelectRow(selectedRows.filter((rowId) => rowId !== id));
    } else {
      onSelectRow([...selectedRows, id]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onNewRow();
    }
  };

  return (
    <StyledTable ref={tableRef}>
      <StickyHeader>
        <tr>
          <th>
            <Typography color="placeholder" margin="none">
              #
            </Typography>
          </th>
          <th>
            <Checkbox checked={isAllRowsSelected} onChange={handleSelectAll} />
          </th>
          {columns.map((column, columnIndex) => (
            <DraggableHeader
              key={column.id}
              columnIndex={columnIndex}
              moveColumn={moveColumn}
            >
              <Typography textStyle="bold" margin="none">
                {column.name}
              </Typography>
            </DraggableHeader>
          ))}
          {/* Action column at the end */}
          <th>
            <Button onClick={onOpenDrawer}>
              <AddIcon />
            </Button>
          </th>
        </tr>
      </StickyHeader>

      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={row.id}>
            <td>
              <Typography color="placeholder" margin="none">
                {rowIndex + 1}
              </Typography>
            </td>
            <td>
              <Checkbox
                checked={selectedRows.includes(row.id)}
                onChange={() => handleSelectRow(row.id)}
              />
            </td>
            {columns.map((col, columnIndex) => (
              <DraggableCell
                key={`${row.id}-${col.id}`}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                moveRow={onMoveRow}
                moveColumn={moveColumn}
              >
                <Input
                  defaultValue={
                    row.cells.find((c) => c.columnId === col.id)?.value
                  }
                  onChange={(e) => handleCellUpdate(e, row, col.id)}
                  onKeyDown={handleKeyDown}
                />
              </DraggableCell>
            ))}
            {/* Action row cell at the end */}
            <td>
              <RowOptions
                disableDown={rowIndex === rows.length - 1}
                disableUp={rowIndex === 0}
                onMoveDown={() => moveRowDown(row.id)}
                onMoveUp={() => moveRowUp(row.id)}
                onDelete={() => onDeleteRows([row.id])}
              />
            </td>
          </tr>
        ))}

        {/* Add new row */}
        <tr>
          <td />
          <td />
          <td>
            <Button onClick={onNewRow}>
              <Typography textStyle="bold" margin="none">
                {formatMessage({ id: 'add.row' })}
              </Typography>
            </Button>
          </td>
          {columns.map((col) => (
            <td key={col.id} />
          ))}
        </tr>

        {/* Table footer */}
        <tr>
          <td />
          <td />
          {columns.map((col) => (
            <td key={col.id}>{null}</td>
          ))}
          <td />
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default DraggableTable;

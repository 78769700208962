import styled from '@emotion/styled';
import React from 'react';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';

const ItemType = 'TABLE_ITEM';

const Td = styled.td`
  text-align: start;
  cursor: grab;
`;

type DragItem = {
  rowIndex: number;
  columnIndex: number;
  isRow: boolean;
};

type DraggableCellProps = {
  children: React.ReactNode;
  columnIndex: number;
  rowIndex: number;
  disableDragging?: boolean;
  moveRow: (fromIndex: number, toIndex: number, columnIndex: number) => void;
  moveColumn: (
    fromRowIndex: number,
    fromColumnIndex: number,
    toRowIndex: number,
    toColumnIndex: number
  ) => void;
};

const DraggableCell = ({
  children,
  columnIndex,
  rowIndex,
  disableDragging = false,
  moveRow,
  moveColumn,
}: DraggableCellProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { rowIndex, columnIndex, isRow: false },
    canDrag: () => !disableDragging,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    canDrop: () => !disableDragging,
    hover: (item: DragItem) => {
      if (item.isRow) {
        moveRow(item.rowIndex, rowIndex, columnIndex);
      } else {
        moveColumn(item.rowIndex, item.columnIndex, rowIndex, columnIndex);
      }
    },
  });

  return (
    <Td
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {children}
    </Td>
  );
};

export default DraggableCell;

import { AgoyDocument, AgoyDocumentStructure } from './document';
import { AgoyTableColumn, AgoyTableRow } from './table';
import {
  BooleanCell,
  Cell,
  isCellWithWarning,
  isField,
  isFormatMessageCell,
  isMultiReferenceCell,
  isReferenceCell,
  NumberCell,
  ReferenceCell,
  StringCell,
} from '../Cell';
import { traverseDocument } from './traverse';
import { isResolveReferenceError } from '..';

const collectFromRow = <T extends Cell, U>(
  baseId: string,
  row: AgoyTableRow,
  columns: AgoyTableColumn[],
  filter: (cell: Cell) => cell is T,
  collector: (id: string, cell: T) => void
) => {
  if (row.active !== false && row.cells) {
    Object.entries(row.cells)
      .filter((column): column is [string, T] => filter(column[1]))
      .filter(
        (column) =>
          columns.find((item) => column[0] === item.id)?.active !== false
      )
      .forEach(([colId, cell]) => {
        collector(`${baseId}.${colId}`, cell);
      });
  }

  if (typeof row.active === 'object' && filter(row.active)) {
    collector(`${baseId}.active`, row.active);
  }

  if (row.rows) {
    row.rows.forEach((row) => {
      collectFromRow(`${baseId}.${row.id}`, row, columns, filter, collector);
    });
  }
};

const collect = <T extends Cell, U extends AgoyDocumentStructure>(
  data: AgoyDocument<U>,
  contentDefinition: U,
  filter: (cell: Cell) => cell is T,
  collector: (id: string, cell: T) => void,
  activeCollector?: (id: string, active: boolean) => void
): void => {
  traverseDocument(
    null,
    contentDefinition,
    data,
    {},
    {
      field: (key, id, props) => {
        if (filter(props.node)) {
          collector(id, props.node);
        }
        return props;
      },
      table: (key, id, props) => {
        props.node.rows.forEach((row) => {
          collectFromRow(
            `${id}.${row.id}`,
            row,
            props.node.columns,
            filter,
            collector
          );
        });
        if (
          typeof props.node.active === 'object' &&
          filter(props.node.active)
        ) {
          collector(id, props.node.active);
        }
        return props;
      },
      boolean: (key, id, props) => {
        if (typeof props.node === 'object' && filter(props.node)) {
          collector(id, props.node);
          return props;
        }

        const splitId = id.split('.');
        if (
          activeCollector &&
          splitId[splitId.length - 1] === 'active' &&
          typeof props.node === 'boolean'
        ) {
          activeCollector(id, props.node);
        }

        return props;
      },
    }
  );
};

export const collectReferences =
  <T extends AgoyDocumentStructure>(contentDefinition: T) =>
  (
    data: AgoyDocument<T>,
    baseReferences: Record<string, string>
  ): Record<string, string> => {
    const refs: Record<string, string> = { ...baseReferences };
    collect(data, contentDefinition, isReferenceCell, (id, cell) => {
      refs[id] = cell.reference;
    });
    collect(data, contentDefinition, isFormatMessageCell, (id, cell) => {
      if (cell.parameterReferences) {
        Object.entries(cell.parameterReferences).forEach(([key, ref]) => {
          refs[`${id}.${key}`] = ref;
        });
      }
    });
    collect(data, contentDefinition, isMultiReferenceCell, (id, cell) => {
      cell.references.forEach((ref, index) => {
        refs[`${id}-${index}`] = ref;
      });
    });
    collect(data, contentDefinition, isField, (id, cell) => {
      if (typeof cell.active === 'object') {
        refs[`${id}.active`] = cell.active.reference;
      }
    });
    collect(data, contentDefinition, isCellWithWarning, (id, cell) => {
      refs[`${id}-warning`] = cell.warning.reference;
    });
    return refs;
  };

const isValueCell = (
  cell: Cell
): cell is NumberCell | BooleanCell | StringCell => {
  return (
    cell.type === 'number' || cell.type === 'boolean' || cell.type === 'string'
  );
};

export const collectValues =
  <T extends AgoyDocumentStructure>(contentDefinition: T) =>
  (
    data: AgoyDocument<T>
  ): Record<string, string | number | boolean | undefined> => {
    const values: Record<string, string | number | boolean | undefined> = {};
    collect(
      data,
      contentDefinition,
      isValueCell,
      (id, cell) => {
        values[id] = cell.value;
      },
      (id, active) => {
        values[id] = active;
      }
    );
    return values;
  };

/**
 * Collects values from ReferenceCells and NumberCells into a record by id.
 *
 * References that are unresolved or has any error are ignored.
 *
 * @param contentDefinition Document structure
 * @param document The document
 */
export const collectResolvedValues = <T extends AgoyDocumentStructure>(
  contentDefinition: T,
  document: AgoyDocument<T>
): Record<string, string | number | boolean | undefined> => {
  const values: Record<string, string | number | boolean | undefined> = {};
  collect(
    document,
    contentDefinition,
    (
      cell: Cell
    ): cell is ReferenceCell | NumberCell | BooleanCell | StringCell =>
      cell.type === 'number' ||
      cell.type === 'ref' ||
      cell.type === 'boolean' ||
      cell.type === 'string' ||
      cell.type === 'msg',
    (id, cell) => {
      if (!isResolveReferenceError(cell.value)) {
        values[id] = cell.value;
      }
    }
  );
  return values;
};

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/EditOutlined';
import Close from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import Checkbox from '_shared/components/Controls/Checkbox';
import Button from '_shared/components/Buttons/Button';
import { SpecificationColumnType } from '_clients/types/types';
import Alert from '_shared/components/AlertBanners/Alert';
import Typography from '_shared/components/Typography/Typography';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled(Row)`
  height: 48px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dde1e0;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const Buttons = styled(Row)`
  justify-content: flex-end;
  column-gap: 16px;
`;

const Header = styled(Row)`
  justify-content: space-between;
`;

const EditIcon = styled(Edit)`
  font-size: 20px;
`;

const CloseIcon = styled(Close)`
  font-size: 24px;
`;

type ColumnItemProps = {
  column: SpecificationColumnType;
  checked: boolean;
  withData: boolean;
  onCheck: (column: SpecificationColumnType, checked: boolean) => Promise<void>;
};

const ColumnItem = ({
  column,
  checked,
  withData,
  onCheck,
}: ColumnItemProps) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const updateValue = async (value: boolean) => {
    setLoading(true);
    await onCheck(column, value);
    setLoading(false);
  };

  const handleCheck = async (value: boolean) => {
    if (withData && !value) {
      setIsDialogOpen(true);
      return;
    }

    updateValue(value);
  };

  const handleDialogSubmit = async () => {
    await updateValue(false);
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <StyledDialog open={isDialogOpen}>
        <Header>
          <Typography variant="h2" margin="none">
            {formatMessage({ id: 'hidden.specification.removeColumnTitle' })}
          </Typography>
          <IconButton
            disabled={loading}
            size="small"
            onClick={() => setIsDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Header>

        <Alert type="warning">
          <Typography>
            {formatMessage({ id: 'hidden.specification.columnWarning' })}
          </Typography>
        </Alert>
        <Buttons>
          <Button
            label={formatMessage({ id: 'cancel' })}
            variant="text"
            disabled={loading}
            onClick={() => setIsDialogOpen(false)}
          />
          <Button
            label={formatMessage({ id: 'hidden.specification.removeColumn' })}
            color="danger"
            loading={loading}
            onClick={handleDialogSubmit}
          />
        </Buttons>
      </StyledDialog>

      <Checkbox
        checked={checked}
        label={column.name}
        disabled={loading}
        size="small"
        onChange={handleCheck}
      />

      <EditIcon />
    </Container>
  );
};

export default ColumnItem;

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import { Modal as MuiModal, IconButton, Grid, Paper } from '@material-ui/core';
import styled from '@emotion/styled';

import Button from '_shared/components/Buttons/Button';
import Link from 'utils/DownloadLink';

import {
  getDocumentExtension,
  isImageDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';
import { isBlobPDF } from 'utils/fileValidation';
import DisplayImage from './DisplayImage';

const DownloadLink = styled(Link)`
  text-decoration: none;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const AccountInformationContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  background: white;
`;

const AccountInformation = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 1rem;
  align-items: center;
`;

const Modal = styled(MuiModal)`
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: ${(props) => props.theme.spacing(4)}px;
`;
const Column = styled.div`
  width: 25%;
  height: 100%;
`;

const LeftColumn = styled(Column)`
  margin-right: ${(props) => props.theme.spacing(4)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MiddleColumn = styled(Column)`
  flex: 1;
`;

const RightColumn = styled(Column)`
  margin-left: ${(props) => props.theme.spacing(4)}px;
  display: flex;
  flex-direction: column;
`;

/* Magic numbers to align stepper arrow on right side */
const StepNextRecord = styled.div`
  position: absolute;
  top: calc(50% - 28px);
  right: 12.5%;
`;

const StepRecordArrow = styled(IconButton)`
  background: ${(props) => props.theme.palette.common.white};
  font-size: 2.5rem;
  :hover {
    background: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const DownloadAlert = styled(Alert)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  width: 100%;
`;

const AccountInfoWrapper = styled(Grid)`
  flex: 1;
`;

const CloseModal = styled(Grid)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  > * {
    background: ${(props) => props.theme.palette.common.white};
    &:hover {
      background: ${(props) => props.theme.palette.common.white};
    }
  }
`;

interface Props {
  documentSrc: string;
  documentId: string;
  documentName: string;
  information?: React.ReactNode;
  isOpen: boolean;
  isShowDownload?: boolean;
  onClose: () => void;
  onStepBackwards?: () => void;
  onStepForward?: () => void;
  refreshDownloadUrl: () => Promise<void>;
}

const PreviewModal = ({
  documentSrc,
  documentId,
  documentName,
  information,
  isOpen,
  isShowDownload = true,
  onClose,
  onStepBackwards,
  onStepForward,
  refreshDownloadUrl,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  // STATE
  const [downloading, setDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState<string | undefined>();
  const [autoDownload, setAutoDownload] = useState(false);
  const [fileSrc, setFileSrc] = useState('');
  const [isPdf, setIsPdf] = useState(false);

  // Since the browser already downloaded the document for viewing,
  // the download link will fail since the Origin header isn't sent
  // in the cached request. By changing the url for the download,
  // fetch will make a fresh request.
  const downloadSrc = `${documentSrc}&`;

  const extension = getDocumentExtension(documentName);

  const isImage = isImageDocument(extension);

  useEffect(() => {
    const getFile = async () => {
      try {
        const response = await fetch(downloadSrc, {
          method: 'GET',
        });

        if (response.ok) {
          const blob = await response.blob();
          setIsPdf(await isBlobPDF(blob));
          const url = window.URL.createObjectURL(blob);
          setFileSrc(url);
        } else {
          await refreshDownloadUrl();
        }
      } catch (e) {
        await refreshDownloadUrl();
      }
    };

    if (!documentSrc) {
      return;
    }

    if (documentSrc.startsWith('data:')) {
      setFileSrc(documentSrc);
    } else {
      getFile();
    }
  }, [downloadSrc, documentSrc, refreshDownloadUrl]);

  // CALLBACKS

  const handleDownload = async (loading, error) => {
    setDownloading(loading);
    setAutoDownload(false);
    setDownloadError(undefined);
    if (error === '403') {
      try {
        await refreshDownloadUrl();
        if (!autoDownload) {
          setAutoDownload(true);
        }
      } catch {
        setDownloadError(error);
      }
    } else {
      setDownloadError(error);
    }
  };

  const stepBackward = () => {
    if (onStepBackwards) {
      setFileSrc('');
      onStepBackwards();
    }
  };

  const stepForward = () => {
    if (onStepForward) {
      setFileSrc('');
      onStepForward();
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <>
          <LeftColumn>
            {onStepBackwards && (
              <StepRecordArrow disableRipple onClick={stepBackward}>
                <ArrowBackIcon fontSize="large" />
              </StepRecordArrow>
            )}
          </LeftColumn>
          <MiddleColumn>
            {fileSrc && (
              <Paper elevation={2}>
                {isImage && <DisplayImage src={fileSrc} />}
                {isPdf && (
                  <iframe
                    title="pdf"
                    style={{ height: '80vh', width: '100%' }}
                    src={fileSrc}
                  />
                )}
              </Paper>
            )}
          </MiddleColumn>
          <RightColumn>
            <Grid container>
              <AccountInfoWrapper item zeroMinWidth>
                {information && isShowDownload && (
                  <AccountInformationContainer elevation={2}>
                    <AccountInformation>{information}</AccountInformation>
                    {isShowDownload && (
                      <LinkContainer>
                        <DownloadLink
                          href={downloadSrc}
                          title={documentId}
                          onDownloading={handleDownload}
                          autoDownload={autoDownload}
                        >
                          <Button
                            label={formatMessage({
                              id: 'hidden.downloadImage',
                            })}
                            loading={downloading}
                            startIcon={<GetAppIcon />}
                          />
                        </DownloadLink>
                      </LinkContainer>
                    )}

                    {downloadError && (
                      <DownloadAlert severity="error">
                        <AlertTitle>
                          {formatMessage({ id: 'hidden.download.error.title' })}
                        </AlertTitle>
                        {formatMessage({ id: 'hidden.download.error.text' })}
                      </DownloadAlert>
                    )}
                  </AccountInformationContainer>
                )}
              </AccountInfoWrapper>
              <CloseModal item zeroMinWidth>
                <IconButton disableRipple onClick={onClose}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </CloseModal>
            </Grid>
            {onStepForward && (
              <StepNextRecord>
                <StepRecordArrow onClick={stepForward}>
                  <ArrowForwardIcon fontSize="large" />
                </StepRecordArrow>
              </StepNextRecord>
            )}
          </RightColumn>
        </>
      </Modal>
    </>
  );
};

export default PreviewModal;

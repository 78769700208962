import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Tooltip, makeStyles } from '@material-ui/core';

import { green } from '@agoy/theme/src/colors';
import {
  formatPercentage,
  ccyFormat,
  getNePersonHelperConfig,
} from '@agoy/common';
import { useSelector } from 'redux/reducers';
import { getFinancialYearEnd } from '@agoy/dates';
import Spacer from '@agoy/annual-report-view/src/UI/helpers/Spacer';
import Typography from '../Typography/Typography';
import When from '../When/When';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: `${green.DARK}`,
    maxWidth: 400,
    fontSize: 14,
    borderRadius: '8px',
  },
  customArrow: {
    color: `${green.DARK}`,
  },
}));

type TooltipPropsType = {
  children: JSX.Element;
  part?: string;
  fieldId?: string;
};

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const MISSING_TRANSLATION = 'MISSING_TRANSLATION';

const InfoTooltip = ({ children, part, fieldId }: TooltipPropsType) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { currentFinancialYear } = useSelector((state) => state.customerView);

  // Null check for currentFinancialYear
  if (!currentFinancialYear) {
    return <>{children}</>;
  }
  const finYearEnd = getFinancialYearEnd(currentFinancialYear);
  const finYearEndNumber = parseInt(finYearEnd, 10);
  const nePersonHelperConfig = getNePersonHelperConfig(finYearEndNumber);

  const text = formatMessage(
    {
      id: `${part}.${fieldId}.text`,
      defaultMessage: MISSING_TRANSLATION,
    },
    {
      maximalAmount: ccyFormat(nePersonHelperConfig?.maximalAmount) ?? 0,
      A8_percentage:
        formatPercentage(nePersonHelperConfig?.A8_percentage, 1) ?? 0,
      A13a_percentage:
        formatPercentage(nePersonHelperConfig?.A13a_percentage, 2) ?? 0,
      A13b_percentage:
        formatPercentage(nePersonHelperConfig?.A13b_percentage, 2) ?? 0,
      B10b_percentage:
        formatPercentage(nePersonHelperConfig?.B10b_percentage, 2) ?? 0,
      i: (chunks) => <i>{chunks}</i>,
      b: (chunks) => <b>{chunks}</b>,
    }
  );

  if (text === MISSING_TRANSLATION) {
    return <>{children}</>;
  }

  const title = formatMessage({
    id: `${part}.${fieldId}.title`,
    defaultMessage: MISSING_TRANSLATION,
  });

  return (
    <Tooltip
      enterNextDelay={250}
      title={
        <Container>
          <When isTrue={title !== MISSING_TRANSLATION}>
            <Typography textStyle="bold" color="onDark" margin="none">
              {title}
            </Typography>
            <Spacer size={1} />
          </When>
          <Typography color="onDark" margin="none">
            {text}
          </Typography>
        </Container>
      }
      placement="right"
      arrow
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default InfoTooltip;

import {
  AgoyColumnTemplate,
  AgoyTable,
  AgoyTableColumn,
  AgoyTableRow,
  AgoyTableRowGenerator,
  RowType,
} from '../AgoyDocument';
import { Cell, ReferenceCell } from '../Cell';

export const createRow = <T extends Cell = Cell>(
  columns: (AgoyTableColumn | undefined)[],
  id: string,
  active: boolean | ReferenceCell,
  cells: (T | undefined)[],
  type?: RowType
): AgoyTableRow<T> => ({
  id,
  active,
  cells:
    cells.length === 0
      ? undefined
      : cells.reduce((obj: Record<string, T>, cell, index) => {
          const col = columns[index];
          if (col && cell !== undefined) {
            obj[col.id] = cell;
          }
          return obj;
        }, {}),
  type,
});

export interface TableBuilder<T extends Cell = Cell> {
  /**
   * Active toggle functionality on the whole table, defaults to true
   */
  setActive: (active: boolean | ReferenceCell) => TableBuilder<T>;
  /**
   * Returns an object (your table) containing your rows in one atribute and your columns in another
   * attribute. The columns are derived from the columns that you passed in to the table
   */
  build: () => AgoyTable<T>;
  /**
   * Used for building your rows. Pass a function to this funciton that you want to use to actually
   * build your rows. Your function will reviece the "RowsBuilder" as its first argument, check
   * the documentation of that method for understanding how to build rows.
   */
  addRows: (
    rows: (builder: RowsBuilder<T>) => AgoyTableRow<T>[]
  ) => TableBuilder<T>;
  newRowTemplate: (...cells: T[]) => TableBuilder<T>;
  newRowTemplateGenerator: (
    generator: AgoyTableRowGenerator<T>
  ) => TableBuilder<T>;
  newColumnTemplate: (
    column: (builder: ColumnBuilder<T>) => AgoyColumnTemplate<T>
  ) => TableBuilder<T>;
}

/**
 * Class for building a table
 * @param baseId The ID you want to assign to the table
 * @param columnIds Column values
 */
export const table = <T extends Cell = Cell>(
  baseId: string,
  ...columnIds: (string | AgoyTableColumn | undefined)[]
): TableBuilder<T> => {
  let tableRows: AgoyTableRow<T>[] = [];
  let newRowTemplate: AgoyTableRow<T> | AgoyTableRowGenerator<T> | undefined;
  let newColumnTemplate: AgoyColumnTemplate<T> | undefined;
  let tableActive: boolean | ReferenceCell | undefined;
  const rowActive = true;

  const columns: (AgoyTableColumn | undefined)[] = columnIds.map((id) =>
    typeof id === 'string' ? { id } : id
  );

  const tableBuilder: TableBuilder<T> = {
    build: () => {
      const buildTable: AgoyTable<T> = {
        active: tableActive || true,
        columns: columns.filter(
          (col): col is AgoyTableColumn => col !== undefined
        ),
        rows: tableRows,
      };
      if (newRowTemplate) {
        buildTable.newRowTemplate = newRowTemplate;
      }
      if (newColumnTemplate) {
        buildTable.newColumnTemplate = newColumnTemplate;
      }
      if (tableActive) {
        buildTable.active = tableActive;
      }
      return buildTable;
    },
    addRows: (buildRows) => {
      tableRows = buildRows(rows(columns, baseId));
      return tableBuilder;
    },
    newRowTemplate: (...cells) => {
      newRowTemplate = createRow(columns, '', rowActive, cells);
      return tableBuilder;
    },
    newRowTemplateGenerator: (generator) => {
      newRowTemplate = generator;
      return tableBuilder;
    },
    newColumnTemplate: (columnTemplate) => {
      newColumnTemplate = columnTemplate(buildColumn());
      return tableBuilder;
    },
    setActive: (active: boolean | ReferenceCell) => {
      tableActive = active;
      return tableBuilder;
    },
  };

  return tableBuilder;
};

export interface RowsBuilder<T extends Cell = Cell> {
  /**
   * Returns the array containing the rows
   */
  build: () => AgoyTableRow<T>[];
  /**
   * Adds a row to your table. It will create an object representing a row and insert it into
   * the array that makes up the rows of the table.
   */
  addRow: (id: string, ...cells: (T | undefined)[]) => RowsBuilder<T>;

  /**
   * Adds a row with a type property to your table. It will create an object representing a row that has type and insert it into
   * the array that makes up the rows of the table.
   */
  addRowWithType: (
    id: string,
    type: RowType,
    ...cells: (T | undefined)[]
  ) => RowsBuilder<T>;

  /**
   * Adds rows inside the latest added row
   */
  addSubRows: (
    subRows: (b: RowsBuilder<T>) => AgoyTableRow<T>[]
  ) => RowsBuilder<T>;
  /**
   * setSortKey, set the sort key for the latest row.
   */
  setSortKey: (sortKey: number) => RowsBuilder<T>;
  /**
   * Sets a template for new rows in the latest added row
   */
  newRowTemplate: (...cells: (T | undefined)[]) => RowsBuilder<T>;
  /**
   * Sets a generator template for new rows in the last added row
   */
  newRowTemplateGenerator: (
    generator: AgoyTableRowGenerator<T>
  ) => RowsBuilder<T>;
  newRowTemplateBuilder: (
    subBuilder: (rows: RowsBuilder<T>) => RowsBuilder<T>
  ) => RowsBuilder<T>;
  /**
   * Sets active for subsequently added rows
   */
  addRowActive: (active: boolean | ReferenceCell) => RowsBuilder<T>;
  /**
   * Sets active for last added row
   */
  setRowActive: (active: boolean | ReferenceCell) => RowsBuilder<T>;
  /**
   * Returns the id of the parent the rows are added to. Could be a table or another row.
   */
  getBaseId: () => string;
}

/**
 * Class for building table rows. When ran, returns "RowsBuilder". This object contains three
 * methods, "build", "addRow" and "addSubRow".
 */
export const rows = <T extends Cell = Cell>(
  columns: (AgoyTableColumn | undefined)[],
  baseId: string
): RowsBuilder<T> => {
  const result: AgoyTableRow<T>[] = [];
  let rowActive: boolean | ReferenceCell = true;

  const builder: RowsBuilder<T> = {
    build: () => result,
    addRow: (id, ...cells) => {
      result.push(createRow(columns, id, rowActive, cells));
      return builder;
    },
    addRowWithType: (id, type, ...cells) => {
      result.push(createRow(columns, id, rowActive, cells, type));
      return builder;
    },
    addSubRows: (subRows) => {
      const lastRow = result[result.length - 1];
      result[result.length - 1] = {
        ...lastRow,
        rows: lastRow.rows
          ? [
              ...lastRow.rows,
              ...subRows(
                rows(columns, `${baseId}.${result[result.length - 1].id}`)
              ),
            ]
          : subRows(rows(columns, `${baseId}.${result[result.length - 1].id}`)),
      };
      return builder;
    },
    setSortKey: (sortKey) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        sortKey,
      };
      return builder;
    },
    newRowTemplate: (...cells) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: createRow(columns, '', rowActive, cells),
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateGenerator: (generator) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: generator,
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateBuilder: (subBuilder) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: subBuilder(
          rows(columns, `${baseId}.${result[result.length - 1].id}`)
        ).build()[0],
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    getBaseId: () => baseId,
    addRowActive: (active) => {
      rowActive = active;
      return builder;
    },
    setRowActive: (active) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        active,
      };
      return builder;
    },
  };
  return builder;
};

export interface ColumnBuilder<T extends Cell = Cell> {
  build: () => AgoyColumnTemplate<T>;
  setType: (type: 'string' | 'number') => ColumnBuilder<T>;
  addCell: (id: string, cell: T, columnId?: string) => ColumnBuilder<T>;
  cellGenerator: (
    generator: (columnId: string, rowId: string, fullRowId: string) => T
  ) => ColumnBuilder<T>;
}

export const buildColumn = <T extends Cell = Cell>(): ColumnBuilder<T> => {
  const result: AgoyColumnTemplate<T> = {
    type: 'number',
  };

  const builder: ColumnBuilder<T> = {
    build: () => result,
    setType: (type) => {
      result.type = type;
      return builder;
    },
    addCell: (id, cell, columnId) => {
      if (result.generator) {
        throw new Error('Cannot use addCell together with cellGenerator');
      }
      const cellTemplate: { id: string; cell: T; columnId?: string } = {
        id,
        cell,
      };
      // use template only for column with this id
      if (columnId) {
        cellTemplate.columnId = columnId;
      }
      if (!result.cells) {
        result.cells = [cellTemplate];
      } else {
        result.cells.push(cellTemplate);
      }
      return builder;
    },
    cellGenerator: (
      generator: (columnId: string, rowId: string, fullRowId: string) => T
    ): ColumnBuilder<T> => {
      if (result.cells) {
        throw new Error('Cannot use cellGenerator together with addCell');
      }
      result.generator = generator;
      return builder;
    },
  };

  return builder;
};

export const parentId = (id: string) => id.substring(0, id.lastIndexOf('.'));
